import React from "react";
import "./BooqBalance.css";
import Solos from "../../images/newcardlogo.svg";

const BooqBalance = () => {
  return (
    <>
      <div className="booq-card d-flex flex-column justify-content-between">
        <div className="token-head d-flex justify-content-between">
          <div>
            <span className="text-white fw-600">Booq Balance</span>
            <h6 className="fs-24 text-white">$ 0</h6>
          </div>
          <div className="text-start">
            <span className="text-white fw-600">Red LOC</span>
            <h6 className="text-white">Upcoming</h6>
          </div>
        </div>
        <div>
          <h4 className="text-white">Coming Soon</h4>
        </div>
        <div className="token-bottom d-flex align-items-end justify-content-end">
          <div>
            <img src={Solos} alt="" height={24} />
          </div>
        </div>
      </div>
    </>
  );
};

export default BooqBalance;
