import React from "react";
import "../../HomePage/style.css";
import "../Header/Header.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../../images/dash-logo.svg";
import { Link,useNavigate } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";

const Header = () => {
  const navigate=useNavigate()
  const isKycDone = localStorage.getItem("isKycCompleted") || "test";
  const isAuthenticated = localStorage.getItem("user-info") || null;

  let userInfo = localStorage.getItem("user-info") || null;
  userInfo = userInfo ? JSON.parse(userInfo) : null;
  console.log(userInfo, "?>>>>>>>>>>>>>>>>>>>>>");
  // const isKycVerified = userInfo
  //   ? userInfo.data.isKYCConfirmed == 1
  //     ? true
  //     : false
  //   : false;
  const isKycVerified=localStorage.getItem('isKycCompleted')=='true'?true:false
  return (
    <>
      {["lg"].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          className="bg-theme py-3"
          fixed="top"
        >
          <Container>
            <Navbar.Brand href="/">
              <img src={logo} alt="" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <Link to="/">
                    <img src={logo} alt="" />
                  </Link>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link href="#home">Home</Nav.Link>
                  <Nav.Link href="#about_us">About Us</Nav.Link>
                  <Nav.Link href="#testimonials">Testimonial</Nav.Link>
                  <Nav.Link href="#faq">Faq</Nav.Link>
                  {/* {  console.log(isKycVerified,'isKycVerified')} */}
                  {
                    // (isAuthenticated && isKycVerified)
                 
                    isAuthenticated ? (
                      <Link
                        to={isKycVerified ? "dashboard" : "kyc"}
                        className="ms-lg-4 me-lg-2 mt-3 mt-lg-0 common-btn px-3 py-2"
                        variant="outline-primary"
                      >
                        Get started
                      </Link>
                    ) : (
                      <>
                        <Link
                          to="login"
                          className="ms-lg-4 me-lg-2 mt-3 mt-lg-0 common-btn px-3 py-2"
                          variant="outline-primary"
                        >
                          Login
                        </Link>
                        <Link
                          to="register"
                          className="mx-lg-2 ms-0 mt-3 mt-lg-0 common-btn register-btn px-3 py-2"
                        >
                          Register
                        </Link>
                      </>
                    )
                  }
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
};

export default Header;
