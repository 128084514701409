import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { BiChevronRight } from "react-icons/bi";
import RedToken from "../components/Red-Token/RedToken";
import SolosReward from "../components/SolosReward/SolosReward";
import BooqBalance from "../components/BooqBalance/BooqBalance";
import Balancecard from "../components/Balancecard/Balancecard";
import Transactions from "../components/Transactions/Transactions";
import {
  getRedTrasactions,
  getSrTrasactions,
} from "../services/propertyServices";
import UserAssets from "./UserAssets";

const Accounts = () => {
  const [Default, setDefault] = useState("Red");
  const [apiData, setApiData] = useState([]);
  // useEffect(()=>{
  //   if(Default == "Red"){
  //     getRedTrasactions().then((res)=>{
  //       // console.log(res?.data,"ressss")
  //       setApiData(res?.data)
  //     }).catch((err)=>{
  //       console.log(err,"errrr")
  //     })
  //   }
  //   else if( Default == "Solos"){
  //     getSrTrasactions().then((res)=>{
  //       // console.log(res?.data,"ressss")
  //       setApiData(res?.data)
  //     }).catch((err)=>{
  //       console.log(err,"errrr")
  //     })
  //   }
  //   else{
  //     console.log("boq apiii")
  //   }

  // },[Default])

  return (
    <>
      <div className="ps-4">
        <div className="dashboard-centerd">
          <div className="row g-0">
            <div className="col-xl-8 col-xxl-9 pe-4 pt-4 border-end center-height">
              <div className="row mb-5">
                <div className="col-12">
                  <div className="d-flex align-items-center justify-content-between mb-1">
                    {/* <h6 className="fw-600 text-black">Transactions</h6> */}
                    {/* <Link className="view-btn" to="/">
                      view all <BiChevronRight />
                    </Link> */}
                  </div>
                </div>
                <div
                  className="col-xxl-4 col-lg-6 col-12 "
                  style={{ cursor: "pointer" }}
                  onClick={() => setDefault("Red")}
                >
                  <RedToken />
                </div>
                <div
                  className="col-xxl-4 col-lg-6 col-12"
                  style={{ cursor: "pointer" }}
                  onClick={() => setDefault("Solos")}
                >
                  <SolosReward />
                </div>
                <div
                  className="col-xxl-4 col-lg-6 col-12"
                  style={{ cursor: "pointer" }}
                  onClick={() => setDefault("Booq")}
                >
                  <BooqBalance />
                </div>
              </div>
              {/* <div className="col-12">
                <Transactions data={Default} />

              </div> */}
              <div className="col-12">
              <UserAssets/>

              </div>
            </div>
            <div className="col-xl-4 col-xxl-3">
              <div className="right-sidebar px-xl-3 pe-4 pt-lg-5">
                <div className=" mb-xl-2 pb-5">
                  <Balancecard />
                </div>
                <div className="mb-2">
                <Transactions data={Default} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Accounts;
