import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./SuperFredCard.css";
import moment from "moment";
import { humanReadableNumber } from "../../common/utility";

const SuperFredCard = ({ data, fetchData, setLoading }) => {

  const navigate = useNavigate();
 
  function numberWithCommas(x) {
    return  Number(parseFloat(x).toFixed(2)).toLocaleString('en', {
      minimumFractionDigits: 2
  });
  }
 
  return (
    <>
      <div className="superFredCard">
        <div className="border rounded p-3">
          <div className="d-flex align-items-center mb-2" style={{justifyContent:'space-between'}}>
            <div className="d-flex align-items-center">
            <figure className="rounded-circle mb-0 tranding-box bg-dark text-center p-2"
            style={{
              background: `url(${data?.image})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}>
              {/* <img src={data?.nftImage} /> */}
            </figure>
            <h6 className="ms-2 mb-0">{data?.series}</h6>
            </div>
            <button className="btn btn-primary">{data?.quantity} QTY</button>
            {/* <h6 className="ms-2 mb-0 text-success">{data?.quantity} SFREDs</h6> */}
          </div>
          <figure className="tranding-img rounded position-relative bg-dark text-center"
          style={{
            background: `url(${data?.image})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            height:'250px'
          }}>
            {/* <img src={data?.nftImage} /> */}
          </figure>
          <div className="d-flex">
            <div className="me-auto">
              <h6>{data?.type}</h6>
              <p className="fs-small">{moment(data?.createdAt).fromNow()}</p>
            </div>
            <p>
              Price:{" "}
              <span className="text-uppercase text-success ms-auto">
                {data?.price} RED / SFRED
              </span>
            </p>
          </div>
          <div className="d-flex">
            <div>
              <a
                style={{ cursor: "pointer" }}
                className="viewAll"
                onClick={() => navigate("/super-fred-details", { state: {data:data,card:'buy'} })}
              >
                View Detail
              </a>
            </div>
            {/* <button
              className="buyButton btn btn-primary btn-sm ms-auto"
              onClick={() => buy(data)}
            >
              Buy Now
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SuperFredCard;
