import React from "react";
import "../../HomePage/style.css";
import { Col, Container, Row } from "react-bootstrap";
import investment from "../../../images/investment.png";

const Investment = () => {
  return (
    <>
      <div className="property-wrapper py-5">
        <Container>
          <Row className="align-items-center py-lg-5">
            <Col lg={{ span: 6, offset: 1, order: "last" }}>
              <div className="f-56 mb-4 d-lg-none d-block">Investments</div>
              <img className="w-100" src={investment} alt="" />
            </Col>
            <Col className="pe-xxl-5 mt-lg-0 mt-4" lg={5}>
              <div className="f-56 mb-4 d-lg-block d-none">Investments</div>
              <p className="f-18 pe-xxl-5">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Investment;
