import React, { useEffect, useState,useContext } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { BiChevronRight, BiSolidCalendarAlt, BiSolidMap } from "react-icons/bi";
import { Link } from "react-router-dom";
import {
  getAllNfts,
  getAllFreds,
  getUserMarketplaceNfts,
  getUserMarketplaceTvts,
  getUserMarketplaceFreds,
  getAllTvts,
  getAllSuperFreds
} from "../services/NftServices";
import { useLocation } from "react-router-dom";
import NftCard from "../components/Nft/NftCard";
import FredCard from "../components/Fred/FredCard";
import Loader from "../components/loader/loader";
import SuperFredCard from "../components/SuperFred/SuperFredCard";
import NoData from "../components/NoDataComp/NoData";
import NoDataImage from "../images/NoDataImage.png";
import SellNftCard from "../components/Nft/SellNftCard";
import SellFredCard from "../components/Fred/SellFredCard";
import SelltvtCard from "../components/Tvt/SellTvtCard";
import TvtCard from "../components/Tvt/TvtCard";
import { ConversionContext } from "../Context/context";

const Marketplace = () => {
  const location = useLocation();
  const activeKey = location?.state?.data;
  const [nftData, setNftData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fredData, setFredData] = useState([]);
  const [tvtData, setTvtData] = useState([]);
  const [superFredData, setSuperFredData] = useState([]);
  const [userMarketplaceNfts, setUserMarketplaceNfts] = useState([]);
  const [userMarketplaceTvts, setUserMarketplaceTvts] = useState([]);
  const [userMarketplaceFredNfts, setUserMarketplaceFredNfts] = useState([]);
  const [filter, setFilter] = useState('all')
  const contextData = useContext(ConversionContext);
  const adminPrivateKey = contextData?.adminPrivateKey;
  const adminReceiveWalletAddress = contextData?.adminReceiveWalletAddress;

  const activeTab = activeKey ? activeKey : "solosmarket";

  const fetchNfts = async (pageNumber = 1, pageSize = 3, search = "") => {
    try {
      setLoading(true);
      const response = await getAllNfts(pageNumber, pageSize, search);
      if (response.status === 200) {
        setNftData(response.data.items);
        setLoading(false);
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching nft data:", error);
    }
  };

  const fetchFreds = async (pageNumber = 1, pageSize = 3, search = "") => {
    try {
      setLoading(true);
      const response = await getAllFreds(pageNumber, pageSize, search);
      if (response.status === 200) {
        setLoading(false);
        setFredData(response.data.items);
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching nft data:", error);
    }
  };
  const fetchTvts = async (pageNumber = 1, pageSize = 3, search = "") => {
    try {
      setLoading(true);
      const response = await getAllTvts(pageNumber, pageSize, search);
      if (response.status === 200) {
        setLoading(false);
        setTvtData(response.data?.filteredData);
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching nft data:", error);
    }
  };

  const fetchSuperFreds = async (pageNumber = 1, pageSize = 3, search = "") => {
    try {
      setLoading(true);
      const response = await getAllSuperFreds(pageNumber, pageSize, search);
      if (response.status === 200) {
        setLoading(false);
        setSuperFredData(response?.data);
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching nft data:", error);
    }
  };

  const fetchUserMarketplaceNfts = async (
    pageNumber = 1,
    pageSize = 3,
    search = ""
  ) => {
    try {
      setLoading(true);
      const response = await getUserMarketplaceNfts(
        pageNumber,
        pageSize,
        search
      );
      if (response.status === 200) {
        setLoading(false);
        setUserMarketplaceNfts(response?.data?.items);
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching nft data:", error);
    }
  };
  const fetchUserMarketplaceTvts = async (
    pageNumber = 1,
    pageSize = 3,
    search = ""
  ) => {
    try {
      setLoading(true);
      const response = await getUserMarketplaceTvts(
        pageNumber,
        pageSize,
        search
      );
      if (response.status === 200) {
        setLoading(false);
        setUserMarketplaceTvts(response?.data);
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching nft data:", error);
    }
  };
  const fetchUserMarketplaceFredNfts = async (
    pageNumber = 1,
    pageSize = 3,
    search = ""
  ) => {
    try {
      setLoading(true);
      const response = await getUserMarketplaceFreds(
        pageNumber,
        pageSize,
        search
      );
      if (response.status === 200) {
        setLoading(false);
        setUserMarketplaceFredNfts(response?.data?.items);
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching nft data:", error);
    }
  };

  useEffect(() => {
    fetchNfts();
    fetchFreds();
    fetchTvts();
    fetchUserMarketplaceNfts();
    fetchUserMarketplaceFredNfts();
    fetchSuperFreds()
    fetchUserMarketplaceTvts()
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <>
      <div className="ps-lg-4 ps-3">
        <div className="dashboard-centerd">
          <div className="row g-0">
            <div className="col-xl-12 col-xxl-12 pe-lg-4 pe-3 pt-4 border-end center-height pb-5">
              <div className="">
                <Tabs
                  defaultActiveKey={activeTab}
                  id="marketplace_tabs"
                  className="marketplace-tabs border-0 mb-4"
                >
                  <Tab eventKey="usermarket" title="User Market">
                    {/* <div className="mt-5" style={{alignItems:'center',justifyContent:'center',display:'flex'}}> */}
                    {/* <img src={NoDataImage} style={{height:'50vh'}} /></div> */}

                    <div style={{ display: 'flex', justifyContent: "space-between", margin: "25px 0px" }}>
                      <div style={{ color: 'black' }}></div>
                      <div style={{ display: 'flex', gap: '10px' }}>
                        <div>
                          <select className="customSelect" aria-label="Default select example" onChange={(e) => setFilter(e.target.value)}>
                            <option selected value="all">All</option>
                            <option value="nfts">NFTs</option>
                            <option value="freds">FREDs</option>
                            <option value="tvts">TVTs</option>
                           
                          </select>
                        </div>
                        <div>
                          <select className="customSelect" aria-label="Default select example">
                            <option selected>Filter by prices</option>
                            <option value="1">Price low to high</option>
                            <option value="2">Price high to low</option>
                            <option value="3">All</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    {filter === "all" || filter === "tvts" ?  <div className="row border-bottom mb-4 g-0">
                      <div className="col-12 d-flex align-items-center justify-content-between mb-4">
                        <h6 className="fw-600 text-black">Trending TVTs</h6>
                        <Link
                          className="view-btn"
                          to="/all-user-marketplace-tvts"
                        >
                          View All <BiChevronRight />
                        </Link>
                      </div>
                      {userMarketplaceTvts?.length > 0 ? (
                        userMarketplaceTvts.map((item, i) => {
                          return (
                            <div
                              key={i}
                              className="col-xxl-4 col-xl-4 col-md-6 col-12 mb-4"
                            >
                              <SelltvtCard
                                data={item}
                                fetchData={fetchUserMarketplaceTvts}
                                setLoading={setLoading}
                              />
                            </div>
                          );
                        })
                      ) : (
                        <NoData />
                      )}
                    </div> : ""}

                  {filter === "all" || filter === "nfts" ?  <div className="row border-bottom mb-4 g-0">
                      <div className="col-12 d-flex align-items-center justify-content-between mb-4">
                        <h6 className="fw-600 text-black">Trending NFTs</h6>
                        <Link
                          className="view-btn"
                          to="/all-user-marketplace-nfts"
                        >
                          View All <BiChevronRight />
                        </Link>
                      </div>
                      {userMarketplaceNfts?.length > 0 ? (
                        userMarketplaceNfts.map((item, i) => {
                          return (
                            <div
                              key={i}
                              className="col-xxl-4 col-xl-4 col-md-6 col-12 mb-4"
                            >
                              <SellNftCard
                                data={item}
                                fetchData={fetchUserMarketplaceNfts}
                                setLoading={setLoading}
                              />
                            </div>
                          );
                        })
                      ) : (
                        <NoData />
                      )}
                    </div> : ""}

                   {filter === "all" || filter === "freds" ? <div className="row border-bottom mb-4 g-0">
                      <div className="col-12 d-flex align-items-center justify-content-between mb-4">
                        <h6 className="fw-600 text-black">Trending FREDs</h6>
                        <Link
                          className="view-btn"
                          to="/all-user-marketplace-freds"
                        >
                          View All <BiChevronRight />
                        </Link>
                      </div>
                      {userMarketplaceFredNfts?.length > 0 ? (
                        userMarketplaceFredNfts.map((item, i) => {
                          return (
                            <div
                              key={i}
                              className="col-xxl-4 col-xl-4 col-md-6 col-12 mb-4"
                            >
                              <SellFredCard
                                data={item}
                                fetchData={fetchUserMarketplaceFredNfts}
                                setLoading={setLoading}
                              />
                            </div>
                          );
                        })
                      ) : (
                        <NoData />
                      )}
                    </div> :""}

                    {/* <div className="row border-bottom mb-4">
                <div className="col-12 d-flex align-items-center justify-content-between mb-4">
                  <h6 className="fw-600 text-black">User Market</h6>
                  <Link className="view-btn" to="/">
                    view all <BiChevronRight />
                  </Link>
                </div>
                <div className="col-xxl-3 col-xl-4 col-md-6 col-12 mb-4">
                  <Possession />
                </div>
                <div className="col-xxl-3 col-xl-4 col-md-6 col-12 mb-4">
                  <Possession />
                </div>
                <div className="col-xxl-3 col-xl-4 col-md-6 col-12 mb-4">
                  <Possession />
                </div>
                <div className="col-xxl-3 col-xl-4 col-md-6 col-12 mb-4">
                  <Possession />
                </div>
              </div> */}
                  </Tab>
                  <Tab eventKey="solosmarket" title="Solos Market">
                    <div style={{ display: 'flex', justifyContent: "space-between", margin: "25px 0px" }}>
                      <div style={{ color: 'black' }}></div>
                      <div style={{ display: 'flex' }}>
                        <div style={{ marginRight: '10px' }}>
                          <select className="customSelect" aria-label="Default select example" onChange={(e) => setFilter(e.target.value)}>
                            <option selected value="all">All</option>
                            <option value="nfts">NFTs</option>
                            <option value="freds">FREDs</option>
                            <option value="tvts">TVTs</option>
                            <option value="superFreds">SUPER FREDs</option>
                          </select>
                        </div>
                        <div>
                          <select className="customSelect" aria-label="Default select example">
                            <option selected>Filter by prices</option>
                            <option value="1">Price low to high</option>
                            <option value="2">Price high to low</option>
                            <option value="3">All</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    {filter === "all" || filter === "nfts" ? <div className="row border-bottom mb-4 g-0">
                      <div className="col-12 d-flex align-items-center justify-content-between mb-4">
                        <h6 className="fw-600 text-black">Trending NFTs</h6>
                        <Link className="view-btn" to="/all-nfts">
                          View All <BiChevronRight />
                        </Link>
                      </div>
                      {nftData?.length > 0 ? (
                        nftData.map((item, i) => {
                          return (
                            <div
                              key={i}
                              className="col-xxl-4 col-xl-4 col-md-6 col-12 mb-4"
                            >
                              <NftCard
                                data={item}
                                fetchData={fetchNfts}
                                setLoading={setLoading}
                                adminPrivateKey={adminPrivateKey}
                                adminReceiveWalletAddress={adminReceiveWalletAddress}
                              />
                            </div>
                          );
                        })
                      ) : (
                        <NoData />
                      )}
                    </div> : ""}
                    {filter === "all" || filter === "freds" ? <div className="row border-bottom mb-4 g-0">
                      <div className="col-12 d-flex align-items-center justify-content-between mb-4">
                        <h6 className="fw-600 text-black">Trending FREDs</h6>
                        <Link className="view-btn" to="/all-freds">
                          View All <BiChevronRight />
                        </Link>
                      </div>
                      {fredData.length > 0 ? (
                        fredData.map((item, i) => {
                          return (
                            <div
                              key={i}
                              className="col-xxl-4 col-xl-4 col-md-6 col-12 mb-4"
                            >
                              <FredCard
                                data={item}
                                fetchData={fetchFreds}
                                setLoading={setLoading}
                                adminPrivateKey={adminPrivateKey}
                                adminReceiveWalletAddress={adminReceiveWalletAddress}
                              />
                            </div>
                          );
                        })
                      ) : (
                        <NoData />
                      )}
                    </div> : ""}

                { filter === "all" || filter === "tvts" ?    <div className="row border-bottom mb-4 g-0">
                      <div className="col-12 d-flex align-items-center justify-content-between mb-4">
                        <h6 className="fw-600 text-black">TVTs</h6>
                        <Link className="view-btn" to="/all-tvts">
                          View All <BiChevronRight />
                        </Link>
                      </div>
                      {tvtData.length > 0 ? (
                        tvtData.map((item, i) => {
                          return (
                            <div
                              key={i}
                              className="col-xxl-4 col-lg-6 col-12 mb-4"
                            >
                              <TvtCard
                                data={item}
                                fetchData={fetchTvts}
                                setLoading={setLoading}
                              />
                            </div>
                          );
                        })
                      ) : (
                        <NoData />
                      )}
                    </div> :""}


                  {filter === "all" || filter === "superFreds" ?  <div className="row border-bottom mb-4 g-0">
                      <div className="col-12 d-flex align-items-center justify-content-between mb-4">
                        <h6 className="fw-600 text-black">Super FRED</h6>
                        <Link className="view-btn" to="/all-sfreds">
                          View All <BiChevronRight />
                        </Link>
                      </div>
                      {superFredData.length > 0 ? (
                        superFredData.map((item, i) => {
                          return (
                            <div
                              key={i}
                              className="col-xxl-4 col-xl-4 col-md-6 col-12 mb-4"
                            >
                              <SuperFredCard
                                data={item}
                                fetchData={fetchSuperFreds}
                                setLoading={setLoading}
                              />
                            </div>
                          );
                        })
                      ) : (
                        <NoData />
                      )}
                    </div> :""}

                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Marketplace;
