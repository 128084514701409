import React, { useState } from "react";
import "./NftCard.css";
import moment from "moment";
// import { IMAGE_URL } from "../../helpers/api_helper";
import BuyNftAbi from "../../Abis/BuyNftAbi.json";
import { ethers } from "ethers";
import AdminContractABI from "../../Abis/AdminContractAbi.json";
import { useNavigate } from "react-router-dom";
import { fireToast } from "../../common/Toster";
import { transferNFT } from "../../services/NftServices";

function capitalize(string) {
  return string
    ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    : null;
}
const BuyNftCard = ({ data, fetchData, setLoading }) => {
  const navigate = useNavigate();
  const [address, setAddress] = useState("");

  const nftImport = async (item) => {
    setLoading(true)
    const res = await window.ethereum
      .request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20", //ERC20
          options: {
            address: item?.contractAddress,
            symbol: item?.symbol,
            decimals: 0,
            image: item?.Image,
            tokenId: "0",
          },
        },
      })
      .then((result) => {
        fireToast('success',"NFT imported to metamask successfully")
        console.log("result??????");
        console.log(result, "autoimportresult");
        setLoading(false)
      })
      .catch((err) => {
        fireToast('error',err.message)
        console.log(err, "autoimporterror");
        setLoading(false)

      });
    console.log(res, "res");
  };

  return (
    <>
      <div className="nftCard" style={{height:'100%'}}>
        <div className="border rounded p-3" style={{height:'100%'}}>
          <div className="d-flex align-items-center mb-2">
            <figure
              className="rounded-circle mb-0 tranding-box bg-dark text-center p-2"
              style={{
                background: `url(${data?.Image})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              {/* <img src={data?.Image} /> */}
            </figure>
            <h6 className="ms-2 mb-0">{capitalize(data?.name)}</h6>
          </div>
          <figure
            className="tranding-img rounded position-relative bg-dark text-center"
            style={{
              background: `url(${data?.Image})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              height:'250px'
            }}
          >
            {/* <img src={data?.Image} /> */}
          </figure>
          <div className="d-flex">
            <div className="me-auto">
              <h6>{capitalize(data?.name)}</h6>
              <p className="fs-small">{moment(data?.createdAt).fromNow()}</p>
            </div>
            <p>
              Price:{" "}
              <span className="text-uppercase text-success ms-auto">
                {data?.price} RED
              </span>
            </p>
          </div>
          <div className="d-flex">
            <div>
              <a
                style={{ cursor: "pointer" }}
                className="viewAll"
                onClick={() => navigate("/nft-details", { state: {data:data,card:'import'} })}
              >
                View Detail
              </a>
            </div>
            <button
              className="buyButton btn btn-primary btn-sm ms-auto"
              onClick={() => nftImport(data)}
            >
              Import
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyNftCard;
