import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import successimage from "../images/success.png";
import { AiOutlineClose } from "react-icons/ai";
const SuccessModal = () => {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Body>
          <span
            style={{ cursor: "pointer" }}
            className=" d-flex justify-content-end mb-2"
            onClick={handleClose}
          >
            <AiOutlineClose />
          </span>

          <div className="d-flex flex-column align-items-center gap-2">
            <img style={{width:"160px", marginBottom:"25px"}} src={successimage} alt="success" />
            <div>
              <h4>Request Sent Successfully</h4>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SuccessModal;
