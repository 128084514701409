import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import logo from "../images/r-logo.svg";
import { Password } from "./Password";
import { resetPassword } from "../services/userService";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { fireToast } from "../common/Toster";

const ForgotPassword = () => {
  let navigate = useNavigate();
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      new_password: "",
      confirm: "",
    },
    validationSchema: Yup.object({
      new_password: Yup.string()
        // .email("Invalid User")
        .required("This Field is Required"),
      new_password: Yup.string()
        .min(8, "Password must be 8 characters long")
        .matches(/[0-9]/, "Password requires a number")
        .matches(/[a-z]/, "Password requires a lowercase letter")
        .matches(/[A-Z]/, "Password requires an uppercase letter")
        .matches(/[^\w]/, "Password requires a symbol"),
      confirm: Yup.string().oneOf(
        [Yup.ref("new_password"), null],
        'Must match "password" field value'
      ),
    }),
    onSubmit: async (values) => {
      console.log(values);
      try {
        // changeApiStatus(true, "");
        const loginResponse = await resetPassword(
          values.new_password,
          localStorage.getItem("token-info")
        );
        if (loginResponse.status === 200) {
          console.log(loginResponse, "loginResponse");
          fireToast("success", loginResponse.message);
          navigate("/login");
        } else {
          // changeApiStatus(false, "");

          throw new Error(loginResponse.error);
        }
      } catch (err) {
        // changeApiStatus(false, "");
      }
    },
  });
  return (
    <div className="auth-bg forgot-mail mh-100 d-flex align-items-center justify-content-center">
      <div className="auth-card py-lg-3">
        <div className="auth-inner">
          <div className="text-center mb-4 mb-lg-5">
            <Link to="/">
              <img className="mx-auto" src={logo} alt="" />
            </Link>
          </div>

          <h1 className="fs-37 mb-md-4 mb-3">Reset Password</h1>

          <Form
            initialValues={{
              name: "",
            }}
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="mb-3">
              <Form.Group>
                <Form.Label className="form-label">New Password</Form.Label>
                <Form.Control
                  name="new_password"
                  placeholder={"Enter new_password"}
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.new_password || ""}
                  invalid={
                    validation.touched.new_password &&
                    validation.errors.new_password
                      ? "true"
                      : "false"
                  }
                />
                {validation.touched.new_password &&
                validation.errors.new_password ? (
                  // <Form.Control.Feedback type="invalid">
                  //   {validation.errors.new_password}
                  // </Form.Control.Feedback>
                  <div className="text-danger">
                    {validation.errors.new_password}
                  </div>
                ) : null}
              </Form.Group>
            </div>

            <div className="mb-3">
              <Form.Group>
                <Form.Label className="form-label">Confirm Password</Form.Label>
                <Form.Control
                  name="confirm"
                  placeholder={"Enter confirm"}
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.confirm || ""}
                  invalid={
                    validation.touched.confirm && validation.errors.confirm
                      ? "true"
                      : "false"
                  }
                />
                {validation.touched.confirm && validation.errors.confirm ? (
                  // <Form.Control.Feedback type="invalid">
                  //   {validation.errors.confirm}
                  // </Form.Control.Feedback>
                  <div className="text-danger">{validation.errors.confirm}</div>
                ) : null}
              </Form.Group>
            </div>

            <div className="mt-3 d-grid">
              <Button
                className="common-btn mt-4 mb-3 w-100"
                variant="primary"
                type="submit"
              >
                <strong>Submit</strong>
              </Button>
            </div>
          </Form>

          {/* <Form>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Password />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Confirm Password</Form.Label>
              <Password />
            </Form.Group>

            <Button
              className="common-btn mt-4 mb-3"
              variant="primary"
              type="submit"
            >
              Submit
            </Button>
          </Form> */}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
