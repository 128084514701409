import React, { createContext, useState, useEffect, useContext } from "react";
import { getConversionRate } from "../services/NftServices";

export const ConversionContext = createContext();
export default function Conversions(props) {
    const [conversionRate, setConversionRate] = useState('');
    const [adminWalletAddress, setAdminWalletAddress] = useState('');
    const [adminReceiveWalletAddress, setAdminReceiveWalletAddress] = useState('');
    const [adminPrivateKey, setAdminPrivateKey] = useState('');

    const getRedConversionRate = async () => {
        try {
          const response = await getConversionRate();
          setConversionRate(response?.data?.conversionrate);
          setAdminWalletAddress(response?.data?.adminWalletAddress)
          setAdminReceiveWalletAddress(response?.data?.receivewalletaddress)
          setAdminPrivateKey(response?.data?.privatekey)
        } catch (error) {
          console.error("Error fetching nft data:", error);
        }
      };

      useEffect(() => {
        getRedConversionRate()       
      }, [])
      

      let data = {
        conversionRate,
        adminWalletAddress,
        adminReceiveWalletAddress,
        adminPrivateKey
      };

      return (
        <ConversionContext.Provider value={data}>
          {props.children}
        </ConversionContext.Provider>
      );
}