import React, { useEffect, useState } from "react";
import "./Balancecard.css";
import {
  getMyInfo,
  getRedPriceInUsd,
  getSrPriceInUsd,
  getConversionRate,
  getMyAssetBalance,
} from "../../services/propertyServices";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { fireToast } from "../../common/Toster";
import Web3, { providers } from "web3";
import RedTokenAbi from "../../Abis/RedTokenAbi.json";
import { FaCopy } from "react-icons/fa6";
import SrTokenAbi from "../../Abis/SrAbi.json";
import JSBI from "jsbi";
const Balancecard = () => {
  const [show, setshow] = useState(false);
  const [show1, setshow1] = useState(false);
  const [assetBal, setAssetBal] = useState("");
  useEffect(() => {
    getdata();
    getAssets();
  }, []);

  const getAssets = async () => {
    try {
      // setLoading(true);
      const response = await getMyAssetBalance();
      if (response.status === 200) {
        // setLoading(false);
        setAssetBal(response?.data?.totalSum);
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      // setLoading(false);
      console.error("Error fetching nft data:", error);
    }
  };

  const [data, setData] = useState();
  const getdata = async () => {
    const myData = await getMyInfo()
      .then((res) => {
        setData(res?.data);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const [balance, setBalance] = useState("");
  const [balanceSr, setBalanceSr] = useState("");
  const [address, setAddress] = useState("");
  const getWallet = async () => {
    if (
      window.web3 &&
      window.web3.currentProvider &&
      window.web3.currentProvider.isCoinbaseWallet
    ) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x89" }], // chainId must be in hexadecimal numbers
        });
      } catch (e) {
        // fireToast("error", "Request Rejected.");
      }
    }
    const web3 = (window.web3 = new Web3(window.ethereum));
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    var account = accounts[0];
    setAddress(accounts[0]);
    // console.log(account,"accoutnt");

    var contractInstance = "";
    contractInstance = new web3.eth.Contract(
      RedTokenAbi,
      "0xfE3D92dE0F25b6e06fd1534A8324aa3563136C7B",
      providers
    );
    var account = accounts[0];
    const RedBalance = await contractInstance._methods
      .balanceOf(account)
      .call();
    const redBalanceFormat = JSBI.divide(
      JSBI.BigInt(Number(RedBalance)),
      JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(18))
    ).toString();
    // console.log(redBalanceFormat, "red balance 90");

    setBalance(redBalanceFormat);

    var contractInstance2 = "";
    contractInstance2 = new web3.eth.Contract(
      SrTokenAbi,
      "0xB7Ed63316fa41A1EEfd5E31a81Ab3144FE51F4C4",
      providers
    );
    var account = accounts[0];
    const SrBalance = await contractInstance2._methods
      .balanceOf(account)
      .call();
    const srBalanceFormat = JSBI.divide(
      JSBI.BigInt(Number(SrBalance)),
      JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(18))
    ).toString();
    setBalanceSr(srBalanceFormat);
    // console.log(srBalanceFormat, "SrBalance 90");
  };

  const [redUsd, setRedUsd] = useState(0);
  const [srUsd, setSrUsd] = useState(0);
  useEffect(() => {
    getWallet();
    // getRedPriceInUsd().then((Res)=>{
    //   setRedUsd(Res?.data?.FredExchangePercentage)
    // })
    getSrPriceInUsd().then((Res) => {
      setSrUsd(Res?.data?.conversionrate);
    });
    getConversionRate().then((res) => {
      setRedUsd(res?.data?.conversionrate);
      // console.log(res?.data?.conversionrate, "conversion 90");
    });
  }, []);

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    fireToast("success", "Copied!");
  };
  function formatCompactNumber(number) {
    const formatter = Intl.NumberFormat("en", { notation: "compact" });
    return formatter.format(number);
  }
  return (
    <>
      <div className="balance-card mx-auto p-3">
        <div className="token-head d-flex align-items-center justify-content-between">
          <div>
            <span className="text-white">Hello,</span>
            <h6 className=" text-white">{data?.name}</h6>
          </div>
          <div className="text-end">
            <span className="text-white">Wallet Address</span>
            <h6 className=" text-white">
              {address?.slice(0, 3)}...{address?.slice(39, 42)}&nbsp;
              <span
                style={{ cursor: "pointer" }}
                onClick={() => handleCopy(address)}
              >
                <FaCopy />
              </span>
            </h6>
          </div>
        </div>
        {/* <div className="mt-4 text-center">
            <p className="f-14 fw-500 text-white mb-0">Total balance</p>
            <p className="f-24 fw-60 text-white mb-4">{!show ? '******' : `$ ${formatCompactNumber(((Number(balance)/Number(redUsd)) +( Number(balanceSr)/Number(srUsd)) )) }`} { show ? <span onClick={()=>setshow(!show)} style={{cursor:"pointer"}}> < AiFillEyeInvisible style={{marginBottom:'5px'}} /></span> 
            // <p className="f-24 fw-60 text-white mb-4">{!show ? '******' : `$ ${formatCompactNumber((Number(balance))+( Number(balanceSr))) }`} { show ? <span onClick={()=>setshow(!show)} style={{cursor:"pointer"}}> < AiFillEyeInvisible style={{marginBottom:'5px'}} /></span> 

            : <span style={{cursor:"pointer"}} onClick={()=>setshow(!show)}> <AiFillEye style={{marginBottom:'5px'}}  /></span>}</p>
        </div> */}
        <div className="mt-4 text-center">
          <p className="f-14 fw-500 text-white mb-0">Total Balance</p>
          {/* <p className="f-24 fw-60 text-white mb-4">{!show1 ? '******' : `$ ${assetBal}`} { show ? <span onClick={()=>setshow1(!show1)} style={{cursor:"pointer"}}> < AiFillEyeInvisible style={{marginBottom:'5px'}} /></span>  */}

          <p className="f-24 fw-60 text-white mb-4">
            {!show1
              ? "******"
              : `$ ${
                  Number(balance) / Number(redUsd) +
                  assetBal +
                  Number(balanceSr) / Number(srUsd)
                }`}{" "}
            {show ? (
              <span
                onClick={() => setshow1(!show1)}
                style={{ cursor: "pointer" }}
              >
                {" "}
                <AiFillEyeInvisible style={{ marginBottom: "5px" }} />
              </span>
            ) : (
              // <p className="f-24 fw-60 text-white mb-4">{!show ? '******' : `$ ${formatCompactNumber((Number(balance))+( Number(balanceSr))) }`} { show ? <span onClick={()=>setshow(!show)} style={{cursor:"pointer"}}> < AiFillEyeInvisible style={{marginBottom:'5px'}} /></span>

              <span
                style={{ cursor: "pointer" }}
                onClick={() => setshow1(!show1)}
              >
                {" "}
                <AiFillEye style={{ marginBottom: "5px" }} />
              </span>
            )}
          </p>
        </div>
      </div>
    </>
  );
};

export default Balancecard;
