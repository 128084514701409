import { useState, useEffect } from "react";
import "./Modal.css";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { FaCircleChevronLeft, FaCopy } from "react-icons/fa6";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Recive from "../../images/recive.svg";
import qr from "../../images/qr.svg";
import copy from "../../images/copy.svg";
import share from "../../images/share.svg";
import { Link } from "react-router-dom";
import { fireToast } from "../../common/Toster";
import { getMyInfo } from "../../services/propertyServices";

const Send = () => {
  const [show, setShow] = useState(false);
  const [username, setUsername] = useState("");
  const [address, setAddress] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getdata = async () => {
    await getMyInfo()
      .then((res) => {
        setUsername(res?.data?.userName);
        setAddress(res?.data?.walletAddress);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
  useEffect(() => {
    getdata();
  }, []);

  return (
    <>
      <Button
        className="bg-transparent border-0 p-0"
        variant="primary"
        onClick={handleShow}
      >
        <span className="token-btn d-flex align-items-center justify-content-center mx-auto mb-1">
          <img src={Recive} alt="" />
        </span>
        Receive
      </Button>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Body>
          <span
            style={{ cursor: "pointer" }}
            className="back-btn fw-500 text-black "
            onClick={() => handleClose()}
          >
            <i className="pe-2 align-middle">
              <FaCircleChevronLeft />
            </i>
            Receive RED
          </span>
          <div className="px-5 text-center my-4">
            <p className="mt-2 mb-4">
              User-Name : <span className="fw-600 text-black">{username} </span>
              {/* <i className="text-black">
                <FaCopy />
              </i> */}
            </p>
            <p className="mt-2 mb-4">
              Wallet Address :{" "}
              <span className="fw-600 text-black">
                {address?.toString()?.slice(0, 6)}...
                {address?.toString()?.slice(36, 42)}{" "}
              </span>
              <i
                className="text-black"
                onClick={() => {
                  navigator.clipboard.writeText(address);
                  fireToast("success", "Copied!");
                }}
              >
                <FaCopy />
              </i>
            </p>
          </div>
          {/* <div className="px-5 text-center my-4">
            <img src={qr} alt="" />
            <p className="mt-2 mb-4">
              Username : <span className="fw-600 text-black">Max@1 </span>
              <i className="text-black">
                <FaCopy />
              </i>
            </p>
            <div className="qr-info d-flex align-items-center p-2 mb-4">
                <i><AiOutlineInfoCircle /> </i> <span>Only send RED Token on this <br /> QR else all your money can be lost</span>
            </div>
            <div className="d-flex align-items-center justify-content-center">
                <Link className="m-3" to="/">
                    <img src={copy} alt="" />
                    <span className="d-block mt-1">Copy</span>
                </Link>
                <Link className="m-3" to="/">
                    <img src={share} alt="" />
                    <span className="d-block mt-1">Share</span>
                </Link>
            </div>
          </div> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Send;
