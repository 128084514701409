import React from "react";
import "../../HomePage/style.css"
import "../Banner/HomeBanner.css" 
import { Col, Container, Row } from "react-bootstrap";

const HomeBanner = () => {
  return (
    <>
      <div className="home-banner" id="home">
        <Container className="pt-xxl-5">
          <Row className="pt-xxl-5">
            <Col>
              <h1 className="mb-4">Your all new platform for <span>investing</span> and <span>leasing</span></h1>
              <p>
                <strong>Over 25 million websites</strong> use our software and training videos to
                grow their traffic, email subscribers, and revenue.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default HomeBanner;
