import React, { useState } from "react";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";

const CountryFlag = () => {
  const [phone1, setPhone1] = useState();
  function normalizeHtml(str) {
    return setPhone1(str && str.replace(/&nbsp;|\u202F|\u00A0/g, " "));
  }
  return (
    <>
      <div className="country-field">
        <PhoneInput
          country={"us"}
          value={phone1}
          onChange={(phone) => normalizeHtml(phone)}
        />
      </div>
    </>
  );
};

export default CountryFlag;
