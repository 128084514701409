import React, { useState } from "react";
import TopNav from "../top-nav/TopNav";
import DashboardLeft from "../../pages/DashboardLeft";
import { Outlet, useLocation } from "react-router-dom";

export function Layout({ children }) {
  const location = useLocation();
  const pathname = location?.pathname;
  const lastSegment = pathname.split("/").pop();
  const [custom, setCustom]=useState(false)
  return (
    <>
      <div className={`dashboard-page d-flex ${custom? 'smallSidebar' : ""}`}>
        <div className="dashboard-left">
          <DashboardLeft />
        </div>
        <div className="dashboard-right">
          <TopNav setCustom={setCustom} custom={custom} title={lastSegment} />
          <div className="w-full">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}
export const getDashboardLayout = function (page, pageProps) {
  return <Layout {...pageProps}>{page}</Layout>;
};

export default Layout;
