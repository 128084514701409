import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { BiChevronRight, BiSolidCalendarAlt, BiSolidMap } from "react-icons/bi";
import { Link } from "react-router-dom";
import Possession from "../components/Possessions/Possession";
import Cardimag from "../images/img.png";
import Buy from "../images/icon7.svg";
import pdf from "../images/pdf.svg";

import { getallProperties } from "../services/propertyServices";

import { useLocation } from "react-router-dom";
import { dateFormat } from "../common/utility";
import { getPossession } from "../services/propertyServices";

import NoData from "../components/NoDataComp/NoData";
import Loader from "../components/loader/loader";
import { useNavigate } from "react-router-dom";
const Possessions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location?.state, "123");

  const [loading, setLoading] = useState(true);
  
  const [possessionData, setPossessionData] = useState([]);
  const [possessions, setPossessions] = useState([]);

  const fetchpossessions = async () => {
    try {
      const response = await getallProperties(location?.state);
      setPossessions(response.data);
      console.log(response.data, "response");
      setLoading(false)
    } catch (error) {
      console.error("Error fetching cryptocurrency data:", error);
    }
  };

  const possessionlist = async () => {
    try {
      await getPossession()
        .then((res) => {
          setPossessionData(res);
          console.log(res, "resss");
          setLoading(false)
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchpossessions();
    possessionlist();
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <>
      <div className="ps-4">
        <div className="dashboard-centerd">
          <div className="row g-0">
            <div className="col-xl-12 col-xxl-12 pe-4 pt-4 border-end center-height pb-5">
              {/* <div className="d-none">
                <Tabs
                  defaultActiveKey="possessions"
                  id="possessions_tabs"
                  className="possessions-tabs border-0 mb-4"
                >
                  <Tab eventKey="all" title="All Properties">
                    <div className="row mb-5">
                      <div className="col-xxl-3 col-xl-4 col-md-6 col-12 mb-4">
                        <Possession />
                      </div>
                      <div className="col-xxl-3 col-xl-4 col-md-6 col-12 mb-4">
                        <Possession />
                      </div>
                      <div className="col-xxl-3 col-xl-4 col-md-6 col-12 mb-4">
                        <Possession />
                      </div>
                      <div className="col-xxl-3 col-xl-4 col-md-6 col-12 mb-4">
                        <Possession />
                      </div>
                      <div className="col-xxl-3 col-xl-4 col-md-6 col-12 mb-4">
                        <Possession />
                      </div>
                      <div className="col-xxl-3 col-xl-4 col-md-6 col-12 mb-4">
                        <Possession />
                      </div>
                      <div className="col-xxl-3 col-xl-4 col-md-6 col-12 mb-4">
                        <Possession />
                      </div>
                      <div className="col-xxl-3 col-xl-4 col-md-6 col-12 mb-4">
                        <Possession />
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="possessions" title="Possessions">
                    <div className="row mb-5">
                      <div className="col-xxl-3 col-xl-4 col-md-6 col-12 mb-4">
                        <Possession />
                      </div>
                      <div className="col-xxl-3 col-xl-4 col-md-6 col-12 mb-4">
                        <Possession />
                      </div>
                      <div className="col-xxl-3 col-xl-4 col-md-6 col-12 mb-4">
                        <Possession />
                      </div>
                      <div className="col-xxl-3 col-xl-4 col-md-6 col-12 mb-4">
                        <Possession />
                      </div>
                      <div className="col-xxl-3 col-xl-4 col-md-6 col-12 mb-4">
                        <Possession />
                      </div>
                      <div className="col-xxl-3 col-xl-4 col-md-6 col-12 mb-4">
                        <Possession />
                      </div>
                      <div className="col-xxl-3 col-xl-4 col-md-6 col-12 mb-4">
                        <Possession />
                      </div>
                      <div className="col-xxl-3 col-xl-4 col-md-6 col-12 mb-4">
                        <Possession />
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div> */}

              <div className="">
                <div className="row border-bottom mb-4 g-0">
                  <div className="col-12 d-flex align-items-center justify-content-between mb-4">
                    <h6 className="fw-600 text-black">My Possessions</h6>
                    <Link className="view-btn" to="/possession-list">
                      view all <BiChevronRight />
                    </Link>
                  </div>
                  {possessionData?.data?.totalCount > 0 ? (
                    possessionData?.data?.data?.slice(0, 3)?.map((item) => (
                      <div
                        className="col-xxl-4 col-xl-4 col-md-6 col-12 mb-4"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate("/possession-details", {
                            state: item?.propertyId,
                          })
                        }
                      >
                        <div className="posse-card">
                          <div className="posse-img position-relative">
                            <img
                              src={
                                item?.imageURL == null
                                  ? Cardimag
                                  : item?.imageURL
                              }
                              alt=""
                            />
                            <div className="property-fee position-absolute p-3 d-flex align-items-center justify-content-between">
                              <div>
                                <p className="f-15 fw-500 text-white mb-0">
                                  Monthly Fees
                                </p>
                                <p className="f-15 fw-500 text-white mb-0 text-green">
                                  {item?.transactionDetails?.totalRedToken} RED
                                </p>
                              </div>
                              <div className="text-end">
                                <p className="f-15 fw-500 text-white mb-0">
                                  Due On
                                </p>
                                <p className="f-15 fw-500 text-white mb-0">
                                  {item.leaseExpirationDate}{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="posse-content p-3">
                            <h6 className="mb-0">{item?.propertyName}</h6>
                            <p className="f-14">
                              Price <strong>${item?.totalValue}</strong>{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <NoData />
                  )}
                </div>
              </div>
            </div>

            {/* <div className="col-xl-4 col-xxl-3">
              <div className="p-4">
                <div className="d-flex align-items-center justify-content-between">
                  <h6 className="fw-600 text-black">Lease paid</h6>
                  <Link className="view-btn" to="/possessions">
                    view all <BiChevronRight />
                  </Link>
                </div>

                <div className="table-responsive transaction-data">
                  <table className="w-100">
                    <tbody>
                      <tr>
                        <td>
                          <div className="trans-list d-flex align-items-center">
                            <i>
                              <img src={Buy} alt="" />
                            </i>
                            <div className="ps-2">
                              <h6 className="mb-1 fw-600">Paid</h6>
                              <p className="f-12 status completed">Confirmed</p>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h6 className="fw-600 mb-2 text-uppercase">15 red</h6>
                          <p className="f-12">21st, May 2023</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="trans-list d-flex align-items-center">
                            <i>
                              <img src={Buy} alt="" />
                            </i>
                            <div className="ps-2">
                              <h6 className="mb-1 fw-600">Paid</h6>
                              <p className="f-12 status completed">Confirmed</p>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h6 className="fw-600 mb-2 text-uppercase">15 red</h6>
                          <p className="f-12">21st, May 2023</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="trans-list d-flex align-items-center">
                            <i>
                              <img src={Buy} alt="" />
                            </i>
                            <div className="ps-2">
                              <h6 className="mb-1 fw-600">Paid</h6>
                              <p className="f-12 status completed">Confirmed</p>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h6 className="fw-600 mb-2 text-uppercase">15 red</h6>
                          <p className="f-12">21st, May 2023</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="trans-list d-flex align-items-center">
                            <i>
                              <img src={Buy} alt="" />
                            </i>
                            <div className="ps-2">
                              <h6 className="mb-1 fw-600">Paid</h6>
                              <p className="f-12 status completed">Confirmed</p>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h6 className="fw-600 mb-2 text-uppercase">15 red</h6>
                          <p className="f-12">21st, May 2023</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="trans-list d-flex align-items-center">
                            <i>
                              <img src={Buy} alt="" />
                            </i>
                            <div className="ps-2">
                              <h6 className="mb-1 fw-600">Paid</h6>
                              <p className="f-12 status completed">Confirmed</p>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h6 className="fw-600 mb-2 text-uppercase">15 red</h6>
                          <p className="f-12">21st, May 2023</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Possessions;
