import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// import { IMAGE_URL } from "../../helpers/api_helper";
import { FaCircleChevronLeft, FaCopy } from "react-icons/fa6";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "./NftDetail.css";
import AdminContractABI from "../../Abis/AdminContractAbi.json";
import BuyNftAbi from "../../Abis/BuyNftAbi.json";
import { ethers } from "ethers";
import moment from "moment";
import { AiOutlineClose } from "react-icons/ai";
import { FaCalendar, FaClock } from "react-icons/fa";
import { ConversionContext } from "../../Context/context";
import Loader from "../loader/loader";
import { transferNFT, getNftCategories,sellNftOnUserMarketplace } from "../../services/NftServices";
import { fireToast } from "../../common/Toster";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

function capitalize(string) {
  return string
    ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    : null;
}

const SellNftDetail = () => {
  const navigate = useNavigate();
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const contextData = useContext(ConversionContext);
  const location = useLocation();
  const data = location?.state?.data;
  console.log(location, "locations");
  const conversionRate = contextData?.conversionRate;
  
  const buy = async (item) => {
    setLoading(true);
    //admin nft work
    const adminPrivateKey =
      "a3fb646246e5618b80a3eff17483d6d238b2f5dfd7d3615b3068c94d444611a0"; //admin pvt key
    const maticProvider = new ethers.providers.JsonRpcProvider(
      "https://polygon-mumbai.g.alchemy.com/v2/qRpA5Y4Fbeip7pho1zuwxPLWGdbYEhbH"
    );
    const adminSigner = new ethers.Wallet(adminPrivateKey, maticProvider);

    const adminContractAddress = item.contractAddress;
    const adminContract = new ethers.Contract(
      adminContractAddress,
      AdminContractABI,
      adminSigner
    );
    const tokenDecimals = "8";
    const balanceInHash = ethers.utils.parseUnits(
      item?.price.toString(),
      tokenDecimals
    );

    if (window.web3 && window.web3.currentProvider && window.web3.currentProvider.isCoinbaseWallet) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x89" }],
          // params: [{ chainId: '0x5' }],
        });
      } catch (e) {
        fireToast("error", "USER REJECTED THE REQUEST...");
        setLoading(false);
      }
      const provider = new ethers.providers.Web3Provider(window.web3.currentProvider);;
      await provider
        .send("eth_requestAccounts", [])
        .then(async (res) => {
          console.log(res);
          setAddress(res?.[0]);
          const signer = provider.getSigner();
          try {
            const REDContractAddress =
              "0xE3728c6330293B9B2923bd49F9DD31F47d34aaD4";
            const REDContractInstance = new ethers.Contract(
              REDContractAddress,
              BuyNftAbi,
              signer
            );
            await REDContractInstance.transfer(
              "0xf6919e3f7e5E4D3441B3771Bbfd61324D5e8375c",
              balanceInHash,
              { gasLimit: 250000 }
            )
              .then(async (result) => {
                console.log(result, "resultRED");
                console.log(address, "address");
                await adminContract
                  .transferFrom(
                    "0xf6919e3f7e5E4D3441B3771Bbfd61324D5e8375c",
                    res?.[0],
                    0,
                    { gasLimit: 250000 }
                  )
                  .then(async (responseNFT) => {
                    console.log(responseNFT, "resultNFT");
                    try {
                      const response = await transferNFT({
                        amount: item?.price,
                        nftDetailId: item?._id,
                        hashId: responseNFT?.hash,
                        type: "normal",
                      })
                        .then(async (res1) => {
                          console.log(item, "item");
                          setLoading(false);
                          fireToast("success", "NTF buy successfully");
                          navigate("/marketplace");
                          // fetchData();

                          // const res = await window.ethereum.request({
                          //   method: "wallet_watchAsset",
                          //   params: {
                          //     type: "ERC721",
                          //     options: {
                          //       address: item?.contractAddress,
                          //       symbol: item?.symbol,
                          //       decimals: 0,
                          //       image: `https://foo.io/token-image.svg`,
                          //       tokenId: "1",
                          //     },
                          //   },
                          // }).then((result) => {
                          //   console.log('result??????')
                          //   console.log(result,'autoimportresult')
                          // }).catch((err)=>{
                          //   console.log(err,"autoimporterror")
                          // })
                          // console.log(res, "res");
                        })
                        .catch((errApi) => {
                          setLoading(false);
                          fireToast("error", errApi);
                        });
                    } catch (error) {
                      console.error("Error fetching nft data:", error);
                      setLoading(false);
                      fireToast("error", error);
                    }
                  })
                  .catch((err) => {
                    setLoading(false);
                    console.log(err, "errorNFT");
                    fireToast("error", err);
                  });
              })
              .catch((e) => {
                console.log(e.message, "errorRED");
                setLoading(false);
                fireToast(
                  "error",
                  e.message
                    ? e.message.includes("user rejected transaction")
                      ? "User Rejected Transaction"
                      : "Error"
                    : "Something Went Wrong, Please try again."
                );
              });

            // if (data) {
            //   let hash = data?.hash;
            //   // transferNFT(item, hash,'normal')
            // } else {
            //   return;
            // }
          } catch (error) {
            fireToast("error", error);
            console.log(error);
            setLoading(false);
          }
        })
        .catch((e) => {
          setLoading(false);
          console.log(e, ">>>>>>>>>>>>ERROR");
        });
    } else {
      setLoading(false);
      fireToast("error", "Metamask not detected");
    }
  };
  return loading ? (
    <Loader />
  ) : (
    <div className="nftDetail ps-4">
      <Link
        className="back-btn fw-500 text-black pt-4"
        onClick={() => navigate(-1)}
      >
        <i className="pe-2 align-middle">
          <FaCircleChevronLeft />
        </i>
        Back
      </Link>
      <div className="dashboard-centerd">
        <div className="row g-0">
          <div className="col-xl-12 col-xxl-12 pe-4 pt-4 border-end center-height pb-5">
            <div className="row mb-5">
              <div className="col-6 sideImage">
                <figure
                  className="mb-0 imageFigure"
                  style={{
                    background: `url(${data?.Image})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  {/* <img src={data?.Image} /> */}
                </figure>
              </div>
              <div className="col-6 sideText">
                <div className="mainText mb-3">
                  <h4 style={{ color: "#000" }}>{data?.name}</h4>
                  <h6 className="fw-bold" style={{ color: "green" }}>
                    {data?.price} RED {`($${data?.price / conversionRate})`}
                  </h6>
                </div>
                <div className="descText">
                  <h6 style={{ color: "#000" }}>Description</h6>
                  <p>{data?.description}</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 collectionDetails">
                <h6 className="fw-bold" style={{ color: "#000" }}>
                  Collection Details
                </h6>
                <div className="row mb-3">
                  <div className="col-6 mb-2">
                    <FaCalendar /> Published by{" "}
                    <span className="fw-bold" style={{ color: "#000" }}>
                      {data?.publishedBy}
                    </span>{" "}
                  </div>
                  <div className="col-6 mb-2">
                    <FaClock /> Published{" "}
                    <span className="fw-bold" style={{ color: "#000" }}>
                      {moment(data?.createdAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="col-6 mb-2">
                    <FaCalendar /> Available From{" "}
                    <span className="fw-bold" style={{ color: "#000" }}>
                      {moment(data?.createdAt, "YYYY-MM-DD").fromNow()}
                    </span>
                  </div>
                </div>
                <div className="p-3 mintNowText">
                  <h6 style={{ color: "#000" }} className="mb-3">
                    Buy now to get your NFT
                  </h6>
                  <h6 style={{ color: "#000" }} className="mb-3">
                    {data?.price} RED
                  </h6>
                  <h6 className="mb-3">
                    {moment(data?.createdAt).format("DD-MM-YYYY")}{" "}
                    {moment(data?.createdAt).format("h:mm a")}
                  </h6>
                  
                    {/* <div className="mintButton">
                      <button className="btn" onClick={() => buy(data)}>
                        Buy Now
                      </button>
                    </div> */}
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    </div>
  );
};

export default SellNftDetail;
