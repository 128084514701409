const Protectedpaths = {
  getDashboard: () => "/dashboard",
  getAccounts: () => "/accounts",
  getTransactions: ()=> "/transactions",
  getPossessions: () => "/possessions",
  getTVTs:()=>"/tvt",
  getMarketplace: () => "/marketplace",
  getViewAllNfts: () => "/all-nfts",
  getViewAllSellNfts:()=>'/all-user-marketplace-nfts',
  getViewAllSellFreds:()=>"/all-user-marketplace-freds",
  getViewAllFreds: () => "/all-freds",
  getViewAllTvts: () => "/all-tvts",
  getViewAllSfreds: () => "/all-sfreds",
  getViewAllBuyFreds: () => "/all-buy-freds",
  getViewAllBuyNfts: () => "/all-buy-nfts",
  getViewAllBuyTvts: () => "/all-buy-tvts",
  getViewAllBuySfreds:()=>"/all-buy-sfreds",
  getNftDetails: () => "/nft-details",
  getSellNftDetails:()=>"/user-marketplace-nft-details",
  getSellTvtDetails:()=>"/user-marketplace-tvt-details",
  getSellFredDetails:()=>"/user-marketplace-fred-details",
  getFredDetails: () => "/fred-details",
  getTvtDetails:()=>'/tvt-details',
  getSuperFredDetails:()=>'/super-fred-details',
  getSupport: () => "/support",
  getSettings: () => "/Settings",
  getLease: () => "/property-request",
  getPossessionsDetail: () => "/possession-details",
  getPossessionsList: ()=> "/possession-list"
};

// public routes --------------------------------
const Publicpaths = {
  getRoot: () => "/",
  getLogin: () => "/login",
  getRegister: () => "/register",
  getForgotEmail: () => "/forgotEmail",
  getOtp: () => "/otp",
  getForgotOtp: () => "/forgotOtp",
  getForgotPassword: () => "/forgot-password",
  getKyc: () => "/kyc",
};

export { Protectedpaths, Publicpaths };
