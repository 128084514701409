import { ethers } from "ethers";

export const getContractInstance = async (address, abi) => {
    // if (window.web3 && window.web3.currentProvider && window.web3.currentProvider.isCoinbaseWallet) {
        if(window.ethereum){
        const provider = new ethers.providers.Web3Provider(window.web3.currentProvider);;
        const signer = provider.getSigner();
        return await new ethers.Contract(address, abi, signer);
    } else {
        console.log('metamask is not available')
    }
}
export const getConvertedAmount = async (amount, decimal) => {
   return await ethers.utils.parseUnits(amount, decimal);
}