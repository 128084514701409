import React from 'react'
import './NoData.css'

const NoData = () => {
  return (
    <div className='noDataPage'>
        <h6>No Data Available</h6>
    </div>
  )
}

export default NoData