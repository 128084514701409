import React, { useState, useEffect } from "react";
import "./RedToken.css";
import Coins from "../../images/coins.svg";
import Solos from "../../images/newcardlogo.svg";
import Send from "../../components/Modals/Send";
import Receive from "../Modals/Receive";
import Buy from "../Modals/Buy";
import Sell from "../Modals/Sell";
import { walletData } from "../../services/propertyServices";
import { useNavigate } from "react-router-dom";
import { fireToast } from "../../common/Toster";
import RedTokenAbi from "../../Abis/RedTokenAbi.json";
import {
  getRedPriceInUsd,
  getConversionRate,
} from "../../services/propertyServices";
import Web3, { providers } from "web3";
import { Signer, BigNumber } from "ethers";
import JSBI from "jsbi";

const RedToken = (prop) => {
  const [conversionRate, setConversionRate] = useState(0);
  useEffect(() => {
    getConversionRate().then((res) => {
      setConversionRate(res?.data?.conversionrate);
      console.log(res?.data?.conversionrate, "conversion 90");
    });
  }, []);
  const navigate = useNavigate();
  const [data, setData] = useState({ redToken: 0, usd: 0 });
  const fetchWalletData = async () => {
    const wallet = await walletData();
    setData({
      redToken: wallet.data.userInfo.redToken,
      usd: wallet.data.userInfo.usd,
    });
  };
  const [balance, setBalance] = useState("");
  const getWallet = async () => {
    if (
      window.web3 &&
      window.web3.currentProvider &&
      window.web3.currentProvider.isCoinbaseWallet
    ) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x89" }], // chainId must be in hexadecimal numbers
        });
      } catch (e) {
        // fireToast("error", "Request Rejected.");
      }
    }
    const web3 = (window.web3 = new Web3(window.ethereum));
    var contractInstance = "";
    contractInstance = new web3.eth.Contract(
      RedTokenAbi,
      "0xfE3D92dE0F25b6e06fd1534A8324aa3563136C7B",
      providers
    );
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    var account = accounts?.[0] ? accounts?.[0] : accounts?.result?.[0];
    const RedBalance = await contractInstance._methods
      .balanceOf(account)
      .call();
    
    let redBal = RedBalance.toString();
    redBal = Number(redBal).toFixed(2) / 10 ** 18;
    redBal = Math.round(redBal);

    // const redBalanceFormat = JSBI.divide(
    //   JSBI.BigInt(Math.round(Number(RedBalance))),
    //   JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(18))
    // ).toString();
    // setBalance(redBalanceFormat);
    setBalance(redBal);
    // console.log(redBalanceFormat, "redBalanceFormat");
  };

  const [priceinUsd, setPriceinUsd] = useState(0);
  useEffect(() => {
    getWallet();
    fetchWalletData();
    getRedPriceInUsd().then((Res) => {
      setPriceinUsd(Res?.data?.FredExchangePercentage);
      console.log(Res, "res90");
    });
  }, []);

  function formatCompactNumber(number) {
    const formatter = Intl.NumberFormat("en", { notation: "compact" });
    return formatter.format(number);
  }
  return (
    <>
      <div
        className="token-card red-token d-flex flex-column justify-content-between"
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/accounts")}
      >
        <div className="token-head d-flex align-items-center justify-content-between">
          <div>
            <span className="text-white fw-600">RED Tokens</span>
            <h6 className="fs-24 text-white">{balance}</h6>
          </div>
          <div className="text-end">
            <span className="text-white fw-600"> USD</span>
            <h6 className="fs-24 text-white">{balance / conversionRate}</h6>
          </div>
        </div>
        <div className="token-bottom d-flex align-items-end justify-content-between">
          <div className="d-flex align-items-center text-center">
            <a href="#">
              <span className="text-center fs-8 mb-0 text-white fw-500">
                {" "}
                <Send getWallet={getWallet} />
              </span>
            </a>
            <a className="mx-1" href="#">
              <Receive />
            </a>
            <a className="mx-1" href="#">
              <Buy />
              {/* <span className='token-btn d-flex align-items-center justify-content-center mx-auto mb-1'><img src={Buy} alt="" /></span>
                        <span className='text-center fs-8 mb-0 text-white fw-500'>Buy</span> */}
            </a>
            <a className="mx-1" href="#">
              <Sell />
            </a>
          </div>
          <div>
            <img src={Solos} alt="" height={24} />
          </div>
        </div>
      </div>
    </>
  );
};

export default RedToken;
