import { initializeApp } from 'firebase/app';
import { getToken, getMessaging, onMessage } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyDAYNMjZymH4kYShdzONSC96tXYoOOomyg",
    authDomain: "solos-f0d77.firebaseapp.com",
    projectId: "solos-f0d77",
    storageBucket: "solos-f0d77.appspot.com",
    messagingSenderId: "684689753466",
    appId: "1:684689753466:web:6b50002d1bd40949ac02c3",
    measurementId: "G-Z00SMSJJ88"
};
const firebaseApp = initializeApp(firebaseConfig);

const messaging = getMessaging(firebaseApp);
export const getOrRegisterServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    return window.navigator.serviceWorker
      .getRegistration('/firebase-push-notification-scope')
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register('/firebase-messaging-sw.js', {
          scope: '/firebase-push-notification-scope',
        });
      });
  }
  throw new Error('The browser doesn`t support service worker.');
};

export const getFirebaseToken = () =>
  getOrRegisterServiceWorker()
    .then((serviceWorkerRegistration) =>
      getToken(messaging, { vapidKey: 'BAnvXxD0ZicdGxYFLOvpQOaA3dFtcCijo91Fc_zb4vIONXmMRGbN8KA_sytfyT6osw86CCcYPQhTHNohfYyJBSI', serviceWorkerRegistration }));

export const onForegroundMessage = () =>
  new Promise((resolve) => onMessage(messaging, (payload) => resolve(payload)));
