import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// import { IMAGE_URL } from "../../helpers/api_helper";
import { FaCircleChevronLeft, FaCopy } from "react-icons/fa6";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "./TvtDetail.css";
import AdminContractABI from "../../Abis/AdminContractAbi.json";
import BuyNftAbi from "../../Abis/BuyNftAbi.json";
import { BigNumber, Signer, ethers } from "ethers";
import moment from "moment";
import { AiOutlineClose } from "react-icons/ai";
import { FaCalendar, FaClock } from "react-icons/fa";
import { ConversionContext } from "../../Context/context";
import Loader from "../loader/loader";
import { transferNFT, getNftCategories,sellNftOnUserMarketplace, buyTvt } from "../../services/NftServices";
import { fireToast } from "../../common/Toster";
import {
  getConversionRate,
  requestRedToken,
} from "../../services/propertyServices";
import Modal from "react-bootstrap/Modal";
import RedAbi from "../../Abis/RedAbi.json";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { redAddress, polygonMainnetUrl } from '../../Contracts/usdc'
import { getContractInstance, getConvertedAmount } from "../../web3/web3";

function capitalize(string) {
  return string
    ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    : null;
}

const SellTvtDetail = () => {
  const navigate = useNavigate();
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [adminAddress, setAdminAddress] = useState(0);
  const contextData = useContext(ConversionContext);
  const location = useLocation();
  const data = location?.state?.data;
  console.log(location, "locations");
  const conversionRate = contextData?.conversionRate;
  
  const buy = async (values) => {
    try {
      setLoading(true);
      // const amountToBePassed = await getConvertedAmount(amount, 6);
      // const amountToBePassed = ((values?.quantity*data?.tradePrice) * 10 ** 8).toString();
      const amountToBePassed = BigNumber.from(
        values?.quantity * data?.tradePrice
      ).mul(BigNumber.from(10).pow(18));

      //   const amount = ethers.parseUnits("100000000", 6);
      // console.log(Number(amount),"amountamount");

      console.log(amountToBePassed, "amount");
      const mywalletAddress = await window.ethereum.send("eth_requestAccounts");
      const instance = await getContractInstance(redAddress, RedAbi, Signer);
      console.log(instance, "instance");

      const approveReponse = await instance.transfer(
        adminAddress,
        amountToBePassed,
        {
          gasLimit: 300000,
        }
      );

      const response = await approveReponse.wait();
      if (response.status) {
        console.log(response.status, ">>>>>>>>");
        console.log(values, "vals");
        try {
          setLoading(true);
          const response = await buyTvt({
            walletAddress: mywalletAddress?.[0]
              ? mywalletAddress?.[0]
              : mywalletAddress?.result?.[0],
            quantity: values?.quantity,
            id: data?._id,
            // categories:data?.categories,
            // series:data?.series,
            // description:data?.description,
            price: data?.price,
            // image:data?.image
          })
            .then(async (res) => {
              setLoading(false);
              fireToast("success", "TVT buy successfully");
              navigate("/marketplace");
            })
            .catch((errApi) => {
              setLoading(false);
              fireToast("error", errApi);
            });
        } catch (error) {
          console.error("Error fetching nft data:", error);
          setLoading(false);
          fireToast("error", error);
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const getData = async () => {
    const exChangeRate = await getConversionRate();
    const { conversionrate, receivewalletaddress } = exChangeRate.data;
    setAdminAddress(receivewalletaddress);
  };

  useEffect(()=>{
    getData()
  },[])
  return loading ? (
    <Loader />
  ) : (
    <div className="nftDetail ps-4">
      <Link
        className="back-btn fw-500 text-black pt-4"
        onClick={() => navigate(-1)}
      >
        <i className="pe-2 align-middle">
          <FaCircleChevronLeft />
        </i>
        Back
      </Link>
      <div className="dashboard-centerd">
        <div className="row g-0">
          <div className="col-xl-12 col-xxl-12 pe-4 pt-4 border-end center-height pb-5">
            <div className="row mb-5">
              <div className="col-6 sideImage">
                <figure
                  className="mb-0 imageFigure"
                  style={{
                    background: `url(${data?.image})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  {/* <img src={data?.Image} /> */}
                </figure>
              </div>
              <div className="col-6 sideText">
                <div className="mainText mb-3">
                  <h4 style={{ color: "#000" }}>{data?.name}</h4>
                  <h6 className="fw-bold" style={{ color: "green" }}>
                    {data?.price} RED {`($${data?.price / conversionRate})`}
                  </h6>
                </div>
                <div className="descText">
                  <h6 style={{ color: "#000" }}>Description</h6>
                  <p>{data?.description}</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 collectionDetails">
                <h6 className="fw-bold" style={{ color: "#000" }}>
                  Collection Details
                </h6>
                <div className="row mb-3">
                  <div className="col-6 mb-2">
                    <FaCalendar /> Published by{" "}
                    <span className="fw-bold" style={{ color: "#000" }}>
                      {data?.publishedBy}
                    </span>{" "}
                  </div>
                  <div className="col-6 mb-2">
                    <FaClock /> Published{" "}
                    <span className="fw-bold" style={{ color: "#000" }}>
                      {moment(data?.createdAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="col-6 mb-2">
                    <FaCalendar /> Available From{" "}
                    <span className="fw-bold" style={{ color: "#000" }}>
                      {moment(data?.createdAt, "YYYY-MM-DD").fromNow()}
                    </span>
                  </div>
                </div>
                <div className="p-3 mintNowText">
                  <h6 style={{ color: "#000" }} className="mb-3">
                    Buy now to get your TVT
                  </h6>
                  <h6 style={{ color: "#000" }} className="mb-3">
                    {data?.price} RED
                  </h6>
                  <h6 className="mb-3">
                    {moment(data?.createdAt).format("DD-MM-YYYY")}{" "}
                    {moment(data?.createdAt).format("h:mm a")}
                  </h6>
                  
                    {/* <div className="mintButton">
                      <button className="btn" onClick={() => buy(data)}>
                        Buy Now
                      </button>
                    </div> */}
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    </div>
  );
};

export default SellTvtDetail;
