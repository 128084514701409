import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
// import { IMAGE_URL } from "../../helpers/api_helper";
import "./FredDetail.css";
import moment from "moment";
import { ethers } from "ethers";
import RedAbi from "../../Abis/RedAbi.json";
import FredAbi from "../../Abis/FredAbi.json";
import { FaCalendar, FaClock } from "react-icons/fa";
import { ConversionContext } from "../../Context/context";
import { FaCircleChevronLeft, FaCopy } from "react-icons/fa6";
import { AiOutlineClose } from "react-icons/ai";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Loader from "../loader/loader";
import { redAddress,polygonMainnetUrl } from "../../Contracts/usdc";
import { fireToast } from "../../common/Toster";
import {
  transferNFT,
  sellNftOnUserMarketplace,
} from "../../services/NftServices";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
function capitalize(string) {
  return string
    ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    : null;
}
const FredDetail = () => {
  const [address, setAddress] = useState("");
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const contextData = useContext(ConversionContext);
  const location = useLocation();
  const data = location?.state?.data;
  const card = location?.state?.card;
  const conversionRate = contextData?.conversionRate;
  const adminPrivateKey=location?.state?.adminPrivateKey;
  const adminReceiveWalletAddress=location?.state?.adminReceiveWalletAddress;
  console.log(adminPrivateKey,adminReceiveWalletAddress, "locations");
  console.log(data, "data");
  const handleClose = () => setShow(false);
  const fredImport = async (item) => {
    setLoading(true);

    const res = await window.ethereum
      .request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC721",
          options: {
            address: item?.contractAddress,
            symbol: item?.symbol,
            decimals: 0,
            image: item?.nftImage,
            tokenId: "0",
          },
        },
      })
      .then((result) => {
        fireToast("success", "FRED imported to metamask successfully");
        console.log("result??????");
        console.log(result, "autoimportresult");
        setLoading(false);
      })
      .catch((err) => {
        fireToast("error", err.message);
        console.log(err, "autoimporterror");
        setLoading(false);
      });
    console.log(res, "res");
  };

  const buy = async (item) => {
    setLoading(true);
    console.log(item, "item");

    if (window.web3 && window.web3.currentProvider && window.web3.currentProvider.isCoinbaseWallet) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x89" }],
          // params: [{ chainId: '0x5' }],
        });
      } catch (e) {
        fireToast("error", "USER REJECTED THE REQUEST...");
        setLoading(false);
      }
      const provider = new ethers.providers.Web3Provider(window.web3.currentProvider);;
      await provider
        .send("eth_requestAccounts", [])
        .then(async (res) => {
          console.log(res);
          setAddress(res?.[0]?res?.[0]:res?.result?.[0]);
          const signer = provider.getSigner();

          try {
            const redContractInstance = new ethers.Contract(
              redAddress,
              RedAbi,
              signer
            );
            // console.log(redContractInstance,'this is fred Contract>>>>')
            const tokenDecimals = await redContractInstance.decimals();
            const balanceInHash = ethers.utils.parseUnits(
              item?.amount.toString(),
              tokenDecimals
            );

            if (balanceInHash > 0) {
              // transfer red
              redContractInstance
                .transfer(
                  adminReceiveWalletAddress,
                  balanceInHash,
                  { gasLimit: 250000 }
                )
                .then(async (res1) => {
                 
                  const myProvider = new ethers.providers.JsonRpcProvider(
                    polygonMainnetUrl
                  );

                  const adminSigner = new ethers.Wallet(
                    adminPrivateKey,
                    myProvider
                  );

                  console.log(adminSigner, "adminSigner>>>>");
                  const FREDContractAddress = item?.contractAddress;
                  const FREDCONTRACT = new ethers.Contract(
                    FREDContractAddress,
                    FredAbi,
                    adminSigner
                  );

                  console.log(FREDCONTRACT, "FREDCONTRACTFREDCONTRACT>>");
                  // transfer NFT
                  const result = await FREDCONTRACT.transferFrom(
                    adminReceiveWalletAddress,
                    res?.[0]?res?.[0]:res?.result?.[0],
                    0,
                    { gasLimit: 250000 }
                  );
                  console.log(result, "result>>>>");
                  if (result) {
                    let hash = result?.hash;
                    try {
                      const response = await transferNFT({
                        amount: item?.price,
                        nftDetailId: item?._id,
                        hashId: hash,
                        type: "fred",
                      }).then(async (res1) => {
                        console.log(item, "item");
                        // fetchData();
                        setLoading(false);
                        fireToast("success", "FRED buy successfully");
                        navigate("/marketplace");
                        // const res = await window.ethereum.request({
                        //   method: "wallet_watchAsset",
                        //   params: {
                        //     type: "ERC721",
                        //     options: {
                        //       address: item.contractAddress,
                        //       symbol: item.fred_name?.slice(0, 3),
                        //       decimals: 0,
                        //       image: item?.nftImage,
                        //       tokenId: "0",
                        //     },
                        //   },
                        // }).then((result) => {
                        //   console.log(result,'autoimportresult')
                        // }).catch((err)=>{
                        //   console.log(err,"autoimporterror")
                        // })
                        // console.log(res, "res");
                      });

                      console.log(response, "response");
                      console.log("9");
                    } catch (error) {
                      setLoading(false);
                      console.error("Error fetching nft data:", error);
                      fireToast("error", error);
                    }
                  } else {
                    setLoading(false);
                    return;
                  }
                })
                .catch((err) => {
                  console.log(err, "error>>>");
                  fireToast(err, err?.message);
                  setLoading(false);
                });
            } else {
              // setLoading(false)
              console("consosle error");
              setLoading(false);
            }
          } catch (error) {
            fireToast("error", error);
            console.log(error);
            setLoading(false);
          }
        })
        .catch((e) => {
          console.log(e, ">>>>>>>>>>>>ERROR");
          setLoading(false);
        });
    }
  };
  const Schema = Yup.object().shape({
    amount: Yup.string().required("Please enter FRED Amount"),
  });

  const onSubmit = async (values) => {
    console.log(values, "vals");
    try {
      setLoading(true);
      const response = await sellNftOnUserMarketplace({
        price: values?.amount,
        nftId: values?.nftId,
        type: "fred",
      })
        .then(async (res) => {
          setLoading(false);
          fireToast("success", "NFT transfered to marketplace");
          navigate("/marketplace", { state: { data: "usermarket" } });
        })
        .catch((errApi) => {
          setLoading(false);
          fireToast("error", errApi);
        });
    } catch (error) {
      console.error("Error fetching nft data:", error);
      setLoading(false);
      fireToast("error", error);
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <div className="fredDetail ps-4">
      <Link
        className="back-btn fw-500 text-black pt-4"
        onClick={() => navigate(-1)}
      >
        <i className="pe-2 align-middle">
          <FaCircleChevronLeft />
        </i>
        Back
      </Link>
      <div className="dashboard-centerd">
        <div className="row g-0">
          <div className="col-xl-12 col-xxl-12 pe-4 pt-4 border-end center-height pb-5">
            <div className="row mb-5">
              <div className="col-6 sideImage">
                <figure
                  className="mb-0 imageFigure"
                  style={{
                    background: `url(${data?.nftImage})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  {/* <img src={data?.nftImage} /> */}
                </figure>
              </div>
              <div className="col-6 sideText">
                <div className="mainText mb-3">
                  <h4 style={{ color: "#000" }}>{data?.fred_name}</h4>
                  <h6 className="fw-bold" style={{ color: "green" }}>
                    {data?.amount} RED {`($${data?.amount / conversionRate})`}
                  </h6>
                </div>
                <div className="descText">
                  <h6 style={{ color: "#000" }}>Description</h6>
                  <p>{data?.description}</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 collectionDetails">
                <h6 className="fw-bold" style={{ color: "#000" }}>
                  Collection Details
                </h6>
                <div className="row mb-3">
                  <div className="col-6 mb-2">
                    <FaCalendar /> Maturity Date{" "}
                    <span className="fw-bold" style={{ color: "#000" }}>
                      {moment(data?.maturityDate).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="col-6 mb-2">
                    <FaClock /> Monthly Reward{" "}
                    <span className="fw-bold" style={{ color: "#000" }}>
                      {data?.benefitAmount} RED
                    </span>{" "}
                  </div>
                </div>
                <div className="p-3 mintNowText">
                  <h6 style={{ color: "#000" }} className="mb-3">
                    Buy now to get your FRED
                  </h6>
                  <h6 style={{ color: "#000" }} className="mb-3">
                    {data?.amount} RED
                  </h6>
                  <h6 className="mb-3">
                    {moment(data?.maturityDate).format("DD-MM-YYYY")}{" "}
                    {moment(data?.maturityDate).format("h:mm a")}
                  </h6>
                  {card == "import" ? (
                    <div className="mintButton">
                      <button className="btn" onClick={() => fredImport(data)}>
                        Import Now
                      </button>
                      <button
                        className="btn mx-2"
                        onClick={() => setShow(true)}
                      >
                        Sell on Marketplace
                      </button>
                    </div>
                  ) : (
                    <div className="mintButton">
                      <button className="btn" onClick={() => buy(data)}>
                        Buy Now
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Header>
          <h5 className="" style={{ margin: "auto" }}>
            {" "}
            Sell NFT on user marketplace
          </h5>
          <span
            style={{ cursor: "pointer" }}
            className=" d-flex justify-content-end mb-2"
            onClick={handleClose}
          >
            <AiOutlineClose />
          </span>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                fred_name: data?.fred_name,
                description: data?.description,
                startMonth: data?.startMonth,
                endYear: data?.endYear,
                amount: data?.amount,
                benefitAmount: data?.benefitAmount,
                purchaseLimit: data?.purchaseLimit,
                nftId: data?._id,
                type: data?.type,
                years:data?.years
              }}
              validationSchema={Schema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ errors, touched, values, setFieldValue, setTouched }) => (
                <Form className="sellNFT-form">
                  <div className="row align-items-start justify-content-center">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label htmlFor="propertyName">FRED Name:</label>
                          <Field
                            disabled
                            name="fred_name"
                            id="fred_name"
                            placeholder="Enter FRED Name"
                            className="form-control"
                          />
                          {errors.fred_name && touched.fred_name ? (
                            <div className="input-error">
                              {errors.fred_name}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-12 mb-3">
                          <label htmlFor="propertyName">Description:</label>
                          <textarea
                            name="description"
                            value={data?.description}
                            // type='textarea'
                            disabled
                            rows="4"
                            id="description"
                            placeholder="Enter Description"
                            className="form-control"
                          />
                        </div>
                        {
                          data?.type=='fixed'&&
                          <div className="col-md-6 mb-3">
                          <label htmlFor="propertyName">Years:</label>
                          <Field
                            disabled
                            name="years"
                            id="years"
                            placeholder="Enter Years"
                            className="form-control"
                          />
                          {errors.years && touched.years ? (
                            <div className="input-error">
                              {errors.years}
                            </div>
                          ) : null}
                        </div>
                        }
                        {
                          data?.type=='flexible'&&
                          <>
                          <div className="col-md-6 mb-3">
                          <label htmlFor="propertyName">Month:</label>
                          <Field
                            disabled
                            name="startMonth"
                            id="startMonth"
                            placeholder="Enter Month"
                            className="form-control"
                          />
                          {errors.startMonth && touched.startMonth ? (
                            <div className="input-error">
                              {errors.startMonth}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label htmlFor="endYear">Year:</label>
                          <Field
                            disabled
                            name="endYear"
                            id="endYear"
                            placeholder="Enter Year"
                            className="form-control"
                          />
                          {errors.endYear && touched.endYear ? (
                            <div className="input-error">{errors.endYear}</div>
                          ) : null}
                        </div>
                          </>
                        }
                        
                        <div className="col-md-6 mb-3">
                          <label htmlFor="propertyName">
                            Amount (Red Tokens):
                          </label>
                          <Field
                            name="amount"
                            id="amount"
                            placeholder="Enter Amount"
                            className="form-control"
                          />
                          {errors.amount && touched.amount ? (
                            <div className="input-error">{errors.amount}</div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label htmlFor="propertyName">Reward Amount:</label>
                          <Field
                            name="benefitAmount"
                            id="benefitAmount"
                            disabled
                            placeholder="Enter Reward Amount"
                            className="form-control"
                          />
                          {errors.benefitAmount && touched.benefitAmount ? (
                            <div className="input-error">
                              {errors.benefitAmount}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label htmlFor="propertyName">Purchase Limit:</label>
                          <Field
                            name="purchaseLimit"
                            disabled
                            id="purchaseLimit"
                            placeholder="Enter Purchase Limit"
                            className="form-control"
                          />
                          {errors.purchaseLimit && touched.purchaseLimit ? (
                            <div className="input-error">
                              {errors.purchaseLimit}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            className="uploadImageContainern d-flex justify-content-center mb-4"
                            style={{
                              borderRadius: "11px",
                              border: "1px solid lightgray",
                            }}
                          >
                            <img
                              src={data?.nftImage}
                              alt="Avatar"
                              className="image"
                              style={{
                                height: "100px",
                                objectFit: "contain",
                                width: "100px",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <Button
                        className="w-100 common-btn1"
                        variant="primary"
                        type="submit"
                      >
                        Sell
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FredDetail;
