import React from "react";
import HomeBanner from "../components/HomePage/Banner/HomeBanner";
import Header from "../components/HomePage/Header/Header";
import Propertylease from "../components/HomePage/Property/Propertylease";
import Peoplefirst from "../components/HomePage/People/Peoplefirst";
import Investment from "../components/HomePage/Investment/Investment";
import Faq from "../components/HomePage/Faq/Faq";
import Footer from "../components/HomePage/Footer/Footer";
import Testimonials from "../components/HomePage/Testimonials/Testimonials";

const LandingPage = () => {
  return (
    <>
      <Header />
      <HomeBanner />
      <Propertylease />
      <Peoplefirst />
      <Investment />
      <Testimonials />
      <Faq />
      <Footer />
    </>
  );
};

export default LandingPage;
