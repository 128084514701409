import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BiChevronRight } from "react-icons/bi";
import RedToken from "../components/Red-Token/RedToken";
import SolosReward from "../components/SolosReward/SolosReward";
import BooqBalance from "../components/BooqBalance/BooqBalance";
import Possession from "../components/Possessions/Possession";
import Comingsoon from "../images/coming.png";
import Balancecard from "../components/Balancecard/Balancecard";
import Transactions from "../components/Transactions/Transactions";
import { getDashboardLayout } from "../components/Layout/Layout";
import Loader from "../components/loader/loader";
import { walletData } from "../services/propertyServices";
const Dashboard = () => {
  const [data, setData] = useState({ redToken: 0, usd: 0 });
  const fetchWalletData = async () => {
    const wallet = await walletData();
    setData(wallet?.data);
  };
  useEffect(() => {
    fetchWalletData();
  }, []);
  const [loader, setLoader] = useState(false);
  return loader ? (
    <Loader />
  ) : (
    <>
      <div className="ps-lg-4 ps-3">
        <div className="dashboard-centerd mb-5">
          <div className="row g-0">
            <div className="col-xl-8 col-xxl-9 pe-lg-4 pe-3 pt-4 border-end center-height mb-0">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h6 className="fw-600 text-black">Your Accounts</h6>
                {/* <Link className="view-btn" to="/dashboard">
                  view all <BiChevronRight />
                </Link> */}
              </div>

              <div className="row mb-mb-5">
                <div className="col-xxl-4 col-lg-6 col-12 ">
                  <RedToken prop={data} />
                </div>
                <div className="col-xxl-4 col-lg-6 col-12">
                  <SolosReward data={data} />
                </div>
                <div className="col-xxl-4 col-lg-6 col-12">
                  <BooqBalance />
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-between mb-4">
                {/* <h6 className="fw-600 text-black">Your Accounts</h6> */}
                {/* <Link className="view-btn" to="/dashboard">
                  view all <BiChevronRight />
                </Link> */}
              </div>

              {/* <div className="row mb-5">
                <div className="col-lg-4 col-12">
                  <Possession />
                </div>
                <div className="col-lg-4 col-12">
                  <Possession />
                </div>
                <div className="col-lg-4 col-12">
                  <Possession />
                </div>
              </div> */}

              {/* <div className="d-flex align-items-center justify-content-between mb-3">
                <h6 className="fw-600 text-black">Future RED</h6>
              </div> */}

              {/* <div className="row">
                <div className="col-lg-4 col-sm-6">
                  <img src={Comingsoon} alt="" />
                </div>
              </div> */}
            </div>
            <div className="col-xl-4 col-xxl-3">
              <div className="right-sidebar px-xl-3 pe-4 pt-lg-5 ">
                <div className="mb-4 mb-lg-5 pb-lg-5">
                  <Balancecard />
                </div>
                <Transactions data={"Red"} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Dashboard;
