import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FaRegEnvelope } from "react-icons/fa";
import { Password } from "./Password";
import logo from "../images/r-logo.svg";
import "./custom.css";
import CountryFlag from "../components/Countryflag/CountryFlag";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { userRegister } from "../services/userService";
import { fireToast } from "../common/Toster";
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";

const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [username, setUsername] = useState("");
  const [contact, setContact] = useState("");
  const [referral, setReferral] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");

  let navigate = useNavigate();
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      userName: "",
      contact: "",
      countryCode: "",
      referralCode: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is Required"),
      email: Yup.string()
        .email("Invalid Email Format")
        .required("This Field is Required")
        .min(6, "UserName must be 6 characters long")
        // .max(30, "UserName must be 30 characters long")
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          "Invalid Email Format"
        ),
      userName: Yup.string()
        .required("This Field is Required")
        .min(6, "UserName must be 6 characters long")
        .max(30, "UserName must be 30 characters long")
        .matches(/[0-9]/, "UserName requires a number")
        .matches(/[A-Z]/, "UserName requires an uppercase letter")
        .matches(/^[a-zA-Z0-9]+$/, 'UserName cannot contain special character'),
      password: Yup.string()
        // .email("Invalid User")
        .required("This Field is Required")
        // .matches(/^(?=.\d)(?=.[a-z])(?=.[A-Z])(?=.[!@#$%^&*()-_=+{};:'",.<>/?]).{8,}$/,'Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one digit, and one special character.'),
        .min(8, "Password must be 8 characters long")
        .matches(/[0-9]/, "Password requires a number")
        .matches(/[a-z]/, "Password requires a lowercase letter")
        .matches(/[A-Z]/, "Password requires an uppercase letter")
        .matches(/[^\w]/, "Password requires a symbol"),
      confirmPassword: Yup.string().required("Please confirm Your Password").oneOf(
        [Yup.ref("password"), null],
        'Must match "password" field value'
      ),
      contact: Yup.number()
        .typeError("That doesn't look like a phone number")
        .positive("A phone number can't start with a minus")
        .integer("A phone number can't include a decimal point")
        .required("A phone number is required"),
      // referralCode: Yup.string()
      //   .required("This Field is Required")
      //   .min(4, "UserName must be 4 characters long"),
      countryCode: Yup.string().required("This Field is Required"),
      // .min(4, "UserName must be 4 characters long"),
    }),
    onSubmit: async (values) => {
      try {
        // changeApiStatus(true, "");
        const loginResponse = await userRegister(values);
        if (loginResponse.status === 200) {
          console.log(loginResponse, "loginResponse");
          fireToast("success", loginResponse.message);
          localStorage.setItem("contact-info", loginResponse.data.email);
          navigate("/otp");

        } else {
          // changeApiStatus(false, "");

          throw new Error(loginResponse.error);
        }
      } catch (err) {
        console.log(err,'>>>>>>>>>>>>>>>')
        fireToast("error", err?.response?.data?.error);
        // changeApiStatus(false, "");
      }
    },
  });

  return (
    <div className="auth-bg mh-100 d-flex align-items-center justify-content-center">
      <div className="auth-card">
        <div className="auth-inner">
          <div className="text-center mb-2">
            <Link to="/">
              <img className="mx-auto" src={logo} alt="" />
            </Link>
            <h1 className="fs-32 mt-3">Register</h1>
          
          </div>

          <Form
            // className="form-horizontal"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3 user-form">
                  <Form.Group className="position-relative">
                    <Form.Label className="form-label">Name</Form.Label>
                    <Form.Control
                      name="name"
                      className="form-ctl "
                      placeholder={"Enter Name"}
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                    />
                    {validation.touched.name && validation.errors.name ? (
                      // <Form.Control.Feedback type="invalid">
                      //   {validation.errors.email}
                      // </Form.Control.Feedback>
                      <div className="text-danger error-msg">
                        {validation.errors.name}
                      </div>
                    ) : null}
                  </Form.Group>
                </div>

                <div className="mb-3">
                  <Form.Group className="position-relative">
                    <Form.Label className="form-label">Contact</Form.Label>
                    <Form.Control
                    className="form-ctl "
                      name="contact"
                      maxLength={10}
                      placeholder={"Enter Contact Number"}
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.contact || ""}
                      invalid={
                        validation.touched.contact && validation.errors.contact
                          ? true
                          : false
                      }
                    />
                    {validation.touched.contact && validation.errors.contact ? (
                      // <Form.Control.Feedback type="invalid">
                      //   {validation.errors.email}
                      // </Form.Control.Feedback>
                      <div className="text-danger error-msg">
                        {validation.errors.contact}
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="mb-3">
                  <div className="position-relative">
                    <Form.Group className="position-relative">
                      <Form.Label className="form-label">
                        {"Password"}
                      </Form.Label>
                      <Form.Control
                        name="password"
                        className="form-ctl "
                        type={passwordType}
                        value={validation.values.password || ""}
                        placeholder={"Enter Password"}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.password &&
                          validation.errors.password
                            ? true
                            : false
                        }
                      />
                      {passwordType == "password" ? (
                        <span>
                          <AiOutlineEye
                            onClick={() => setPasswordType("text")}
                            className="view_password"
                          />{" "}
                        </span>
                      ) : (
                        <span>
                          <AiOutlineEyeInvisible
                            onClick={() => setPasswordType("password")}
                            className="view_password"
                          />{" "}
                        </span>
                      )}
                      {validation.touched.password &&
                      validation.errors.password ? (
                        // <Form.Control.Feedback type="invalid">
                        //   {validation.errors.password}
                        // </Form.Control.Feedback>
                        <div className="text-danger error-msg">
                          {validation.errors.password}
                        </div>
                      ) : null}
                    </Form.Group>
                  </div>
                </div>
                <div className="mb-3">
                  <Form.Group className="position-relative">
                    <Form.Label className="form-label">Country Code</Form.Label>
                    <Form.Control
                    className="form-ctl "
                      name="countryCode"
                      placeholder={"Enter Country Code"}
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      // value={validation.values.email || ""}
                      // invalid={
                      //   validation.touched.email && validation.errors.email
                      //     ? true
                      //     : false
                      // }
                    />
                    {validation.touched.countryCode &&
                    validation.errors.countryCode ? (
                      // <Form.Control.Feedback type="invalid">
                      //   {validation.errors.email}
                      // </Form.Control.Feedback>
                      <div className="text-danger error-msg">
                        {validation.errors.countryCode}
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <Form.Group className="position-relative">
                    <Form.Label className="form-label">Email</Form.Label>
                    <Form.Control
                    className="form-ctl "
                      name="email"
                      placeholder={"Enter Email"}
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email || ""}
                      invalid={
                        validation.touched.email && validation.errors.email
                          ? "true"
                          : "false"
                      }
                    />
                    {validation.touched.email && validation.errors.email ? (
                      // <Form.Control.Feedback type="invalid">
                      //   {validation.errors.email}
                      // </Form.Control.Feedback>
                      <div className="text-danger error-msg">
                        {validation.errors.email}
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="mb-3">
                  <Form.Group className="position-relative">
                    <Form.Label className="form-label">User Name</Form.Label>
                    <Form.Control
                    className="form-ctl "
                      name="userName"
                      placeholder={"Enter User Name"}
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.userName || ""}
                      invalid={
                        validation.touched.userName &&
                        validation.errors.userName
                          ? true
                          : false
                      }
                    />
                    {validation.touched.userName &&
                    validation.errors.userName ? (
                      // <Form.Control.Feedback type="invalid">
                      //   {validation.errors.userName}
                      // </Form.Control.Feedback>
                      <div className="text-danger error-msg">
                        {validation.errors.userName}
                      </div>
                    ) : null}
                  </Form.Group>
                </div>

                <div className="mb-3">
                  <div className="position-relative">
                    <Form.Group className="position-relative">
                      <Form.Label className="form-label">
                        {"Confirm Password"}
                      </Form.Label>
                      <Form.Control
                      className="form-ctl "
                        name="confirmPassword"
                        type={confirmPasswordType}
                        value={validation.values.confirmPassword || ""}
                        placeholder={"Enter Confirm Password"}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.confirmPassword &&
                          validation.errors.confirmPassword
                            ? true
                            : false
                        }
                      />
                      {/* <img
                      onClick={toggleShowPassword}
                      className=" view view_password"
                      src={showPassword ? Show : Hide}
                      alt="hide"
                    /> */}
                      {confirmPasswordType == "password" ? (
                        <span>
                          <AiOutlineEye
                            onClick={() => setConfirmPasswordType("text")}
                            className="view_password"
                          />{" "}
                        </span>
                      ) : (
                        <span>
                          <AiOutlineEyeInvisible
                            onClick={() => setConfirmPasswordType("password")}
                            className="view_password"
                          />{" "}
                        </span>
                      )}

                      {validation.touched.confirmPassword &&
                      validation.errors.confirmPassword ? (
                        // <Form.Control.Feedback type="invalid">
                        //   {validation.errors.confirmPassword}
                        // </Form.Control.Feedback>
                        <div className="text-danger error-msg">
                          {validation.errors.confirmPassword}
                        </div>
                      ) : null}
                    </Form.Group>
                  </div>
                </div>

                <div className="mb-3">
                  <Form.Group className="position-relative">
                    <Form.Label className="form-label">
                      Referral Code
                    </Form.Label>
                    <Form.Control
                    className="form-ctl "
                      name="referralCode"
                      placeholder={"Enter Referral Code"}
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      // value={validation.values.email || ""}
                      // invalid={
                      //   validation.touched.email && validation.errors.email
                      //     ? true
                      //     : false
                      // }
                    />
                    {validation.touched.referralCode &&
                    validation.errors.referralCode ? (
                      // <Form.Control.Feedback type="invalid">
                      //   {validation.errors.email}
                      // </Form.Control.Feedback>
                      <div className="text-danger error-msg">
                        {validation.errors.referralCode}
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
              </div>

              <div className="mt-3 d-grid">
                <Button
                  className="common-btn mt-4 mb-3 w-100"
                  variant="primary"
                  type="submit"
                >
                  <strong>Submit</strong>
                </Button>
              </div>
            </div>

            <p className="f-15 text-center">
              Don’t have an account?
              <Link to="/login" className="text-theme fw-600 ms-2">
                <u>Sign In</u>
              </Link>
            </p>
          </Form>

        
        </div>
      </div>
    
    </div>
  );
};

export default Register;
