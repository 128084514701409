import React from "react";
import "../../HomePage/style.css";
import "../Footer/Footer.css";
import { Col, Container, Row } from "react-bootstrap";
import logo from "../../../images/dash-logo.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="footer-wrapper py-5">
        <Container>
          <Row>
            <Col className="mb-lg-0 mb-4" lg={6}>
              <Link to="/">
                <img src={logo} alt="" />
              </Link>
              <p className="f-16 mt-lg-4 mb-3">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s.
              </p>
            </Col>
            <Col className="mb-lg-0 mb-4" lg={3}>
              <h3 className="fw-600 mb-lg-4 mb-3 f-16">Site Links</h3>
              <ul>
                <li>
                  <Link to="/">About Us</Link>
                </li>
                <li>
                  <Link to="/">Lease</Link>
                </li>
                <li>
                  <Link to="/">Investments</Link>
                </li>
              </ul>
            </Col>
            <Col lg={3}>
              <h3 className="fw-600 mb-4 f-16">Work With Us</h3>
              <ul>
                <li>
                  <Link to="/">Contact Us</Link>
                </li>

                <li>
                  <Link to="/">FAQ</Link>
                </li>
              </ul>
            </Col>
          </Row>

          <Row className="py-4 py-lg-5">
            <Col className="text-center">
              <p className="f-16">
                Copyright © 2023 Solos. All Rights Reserved.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Footer;
