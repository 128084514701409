import { Protectedpaths } from "./paths";
import Dash from "../images/icon1.svg";
import accounts from "../images/icon2.svg";
import Possessions from "../images/icon3.svg";
import Marketplace from "../images/icon3.svg";
import Support from "../images/icon4.svg";
import Setting from "../images/icon5.svg";
const Sidebarpaths = [
  {
    path: Protectedpaths.getDashboard(),
    title: "Dashboard",
    icon: Dash,
  },
  {
    path: Protectedpaths.getAccounts(),
    title: "Accounts",
    icon: accounts,
  },
  {
    path: Protectedpaths.getTransactions(),
    title: "Transactions",
    icon: accounts,
  },
  {
    path: Protectedpaths.getPossessions(),
    title: "Possessions",
    icon: Possessions,
  },
  {
    path: Protectedpaths.getMarketplace(),
    title: "Marketplaces",
    icon: Marketplace,
  },
  {
    path: Protectedpaths.getSupport(),
    title: "Communications",
    icon: Support,
  },
  {
    path: Protectedpaths.getLease(),
    title: "Property Request",
    icon: Marketplace,
  },
  {
    path: Protectedpaths.getSettings(),
    title: "Settings",
    icon: Setting,
  },
];

export default Sidebarpaths;
