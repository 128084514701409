import React, { useEffect, useState } from "react";
import { BiChevronRight, BiSolidCalendarAlt, BiSolidMap } from "react-icons/bi";
import { Link, useNavigate} from "react-router-dom";
import Possession from "../components/Possessions/Possession";
import sq from "../images/icon-11.svg";
import com from "../images/icon-12.svg";
import interest from "../images/icon-13.svg";
import time from "../images/icon-14.svg";
import acq from "../images/icon-10.svg";
import monthy from "../images/icon-15.svg";
import pool from "../images/icon-16.svg";
import view from "../images/icon-17.svg";
import gym from "../images/icon-18.svg";
import work from "../images/icon-19.svg";
import bike from "../images/icon-23.svg";
import airport from "../images/icon-20.svg";
import baby from "../images/icon-21.svg";
import room from "../images/icon-22.svg";
import Buy from "../images/icon7.svg";
import pdf from "../images/pdf.svg";
import axios from "axios";
import {
  getallProperties,
  transactionToLeaseProperty,
  getLeaseTransaction,
  terminateLease
} from "../services/propertyServices";
import { useLocation } from "react-router-dom";
import { dateFormat } from "../common/utility";
import Web3 from "web3";
import RedTokenAbi from "../Abis/RedTokenAbi.json";
import { fireSuccessToast, fireToast } from "../common/Toster";
import { ethers } from "ethers";
import Loader from "../components/loader/loader";
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import Swal from "sweetalert2";
const PossessionDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location?.state, "state");
  const [loader, setLoader] = useState(false);
  const [possessions, setPossessions] = useState([]);
  const fetchpossessions = async () => {
    try {
      const response = await getallProperties(location?.state);
      setPossessions(response.data);
      console.log(response.data, "response");
    } catch (error) {
      console.error("Error fetching cryptocurrency data:", error);
    }
  };
  const [filterData, setFilterData] = useState([]);
  const [page, setPage] = useState(1);
  const [transaction, setTransaction] = useState([]);
  const transactionList = async () => {
    try {
      await getLeaseTransaction(page)
        .then((res) => {
          console.log(res?.data,"tramsactopm");
          setTransaction(res?.data)
          setFilterData(res?.data?.items?.filter((item)=>item?.propertyId == location?.state))
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  console.log(filterData, "filterData");

  useEffect(() => {
    fetchpossessions();
    transactionList();
  }, [page]);

  //send assets
  const sendCustomAssets = async (
    userToken = "0xf6919e3f7e5E4D3441B3771Bbfd61324D5e8375c",
    amount = 10
  ) => {
    const web3 = (window.web3 = new Web3(window.ethereum));
    try {
      setLoader(true);
      if (window.web3 && window.web3.currentProvider && window.web3.currentProvider.isCoinbaseWallet) {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [
            {
              chainId: `${"0x89"}`,
            },
          ],
        });
      }

      var contractInstance = "";
      contractInstance = new web3.eth.Contract(
        RedTokenAbi,
        "0xE3728c6330293B9B2923bd49F9DD31F47d34aaD4"
      );

      // const newAmount = web3.utils.toHex(
      //   possessions?.propertyDetails?.MonthlyFees * 10 ** 8
      // );
      const newAmount = "1000"; //static value for testing and origional code hide in top

      const walletAddress = await window.ethereum.send("eth_requestAccounts");

      const tx = {
        from: walletAddress[0],
        to: contractInstance._address,
        data: contractInstance.methods
          .transfer(userToken, newAmount)
          .encodeABI(),
      };
      web3.eth
        .sendTransaction(tx)
        .then((res) => {
          setLoader(false);
          fireSuccessToast("Payment successfully Done!");
          transactionToLeaseProperty({agreementId: possessions?.agreementDetails?._id,
          accountType: "Primary",
          propertyId: location?.state,
          amount: possessions?.agreementDetails?.monthlyLeaseFee?.toString()}).then((res)=>{
            console.log(res,"resss")
          })
        })
        .catch((err) => {
          setLoader(false);
          console.log("err", err);
          fireToast("error", err?.message);
        });
    } catch (err) {
      setLoader(false);
      console.log(err, "try error");
    }
  };

  const handleTerminateLease = async() => {
    await terminateLease({agreementId:possessions?.agreementDetails?._id,status:'DECLINED'}).then((Res)=>{
      console.log(Res)
      fireToast("success", "Successfully Terminated!");
      navigate('/property-request')
    }).catch((err)=>{
      console.log(err,"err")
      fireToast("error", err?.response?.data?.error);
    })
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="ps-4">
          <div className="dashboard-centerd">
            <div className="row g-0">
              <div className="col-xl-8 col-xxl-9 pe-4 pt-4 border-end center-height pb-5">
                <div className="">
                  <div className="fger">
                    <div className="row">
                      <div className="col-lg-6 col-12 mb-lg-0 mb-5">
                        <div className="property-block position-relative">
                          <img
                            src={
                              possessions?.imageURL == null
                                ? "https://thumbs.dreamstime.com/z/ramoji-film-city-hyderabad-inside-view-dummy-buildings-shooting-amusement-park-set-135759994.jpg?w=992"
                                : possessions?.imageURL
                            }
                            alt=""
                          />
                          <div className="pro-details position-absolute d-flex align-items-center justify-content-between">
                            <button>
                              <BiSolidCalendarAlt /> Monthly Fees
                              <span className="fw-500">
                                {possessions?.agreementDetails?.monthlyLeaseFee}{" "}
                                RED
                              </span>
                            </button>
                            <button>
                              Next Due :{" "}
                              {dateFormat(
                                possessions?.propertyDetails?.dueDate
                              )}{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 mb-lg-0 mb-4 posse-details">
                        <h2 className="fs-20">{possessions?.propertyName}</h2>
                        <p>
                          <BiSolidMap />{" "}
                          <span>
                            {possessions?.agreementDetails?.city},
                            {possessions?.agreementDetails?.state},
                            {possessions?.agreementDetails?.country}
                          </span>
                        </p>

                        <h5 className="fw-700">Description</h5>
                        <p>{possessions?.description}</p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 mb-lg-0 mb-4">
                        <h6 className="fw-700 text-black mt-5 mb-4">
                          Property Details
                        </h6>
                        <div className="row">
                          <div className="col-md-6 mb-5">
                            <p className="fw-500 t-gray">
                              <i className="me-2">
                                <img src={sq} alt="" width={24} />
                              </i>
                              <strong className="text-black">
                                {" "}
                                {possessions?.propertyDetails?.area}{" "}
                              </strong>{" "}
                              sq ft.
                            </p>
                          </div>
                          <div className="col-md-6 mb-5">
                            <p className="fw-500 t-gray">
                              <i className="me-2">
                                <img src={com} alt="" width={24} />
                              </i>
                              <span className="text-black">
                                {possessions?.propertyDetails?.propertyType}
                              </span>
                            </p>
                          </div>
                          <div className="col-md-6 mb-5">
                            <p className="fw-500 t-gray">
                              <i className="me-2">
                                <img src={interest} alt="" width={24} />
                              </i>
                              <strong className="text-black me-2">
                                {" "}
                                {
                                  possessions?.propertyDetails?.interestPerAnnum
                                }{" "}
                                %
                              </strong>
                              interest/annum
                            </p>
                          </div>
                          <div className="col-md-6 mb-5">
                            <p className="fw-500 t-gray">
                              <i className="me-2">
                                <img src={time} alt="" width={24} />
                              </i>
                              Time period
                              <p className="completed ms-2">
                                {
                                  possessions?.agreementDetails
                                    ?.leaseExpirationDate
                                }
                              </p>
                            </p>
                          </div>
                          <div className="col-md-6 mb-5">
                            <p className="fw-500 t-gray">
                              <i className="me-2">
                                <img src={acq} alt="" width={24} />
                              </i>
                              Acquisition date{" "}
                              <p className="text-black">
                                {possessions?.agreementDetails?.leaseStartDate}
                              </p>
                            </p>
                          </div>
                          <div className="col-md-6 mb-5">
                            <p className="fw-500 t-gray">
                              <i className="me-2">
                                <img src={monthy} alt="" width={24} />
                              </i>
                              Monthly Fees
                              <span className="completed fw-600 ms-2">
                                {possessions?.propertyDetails?.MonthlyFees} RED
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-lg-0 mb-4">
                        <h6 className="fw-700 text-black mt-5 mb-4">
                          Other Details
                        </h6>
                        <div className="row">
                          <div className="col-md-3 mb-3 text-center activity-block">
                            <img src={pool} alt="" />
                            <p>Infinity Pool</p>
                          </div>
                          <div className="col-md-3 mb-3 text-center activity-block">
                            <img src={view} alt="" />
                            <p>Sunset view</p>
                          </div>
                          <div className="col-md-3 mb-3 text-center activity-block">
                            <img src={gym} alt="" />
                            <p>Gym center</p>
                          </div>
                          <div className="col-md-3 mb-3 text-center activity-block">
                            <img src={work} alt="" />
                            <p>Work station</p>
                          </div>
                          <div className="col-md-3 mb-3 text-center activity-block">
                            <img src={bike} alt="" />
                            <p>Free Bike</p>
                          </div>
                          <div className="col-md-3 mb-3 text-center activity-block">
                            <img src={airport} alt="" />
                            <p>Airport suttle</p>
                          </div>
                          <div className="col-md-3 mb-3 text-center activity-block">
                            <img src={baby} alt="" />
                            <p>Baby ground</p>
                          </div>
                          <div className="col-md-3 mb-3 text-center activity-block">
                            <img src={room} alt="" />
                            <p>Meeting room</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 mb-5">
                        <h6 className="fw-600 text-black mb-4">
                          Property Documents
                        </h6>
                        <button className="pdf-btn">
                          <img src={pdf} alt="" />
                          <span className="ms-1">
                            Download property doc pdf
                          </span>
                        </button>
                      </div>

                      <button
                        // className="pay-btn"
                        className="btn pay-btn"
                        onClick={() => sendCustomAssets()}
                      >
                        Pay booking amount
                      </button>
                      <button
                        className="btn pay-btn ms-2"
                        onClick={() =>
                          Swal.fire({
                            title: "Are you sure?",
                            text: "You won't be able to revert this!",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Yes, Terminate it!",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              handleTerminateLease()
                            }
                          })
                        }
                      >
                        Terminate Lease
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-xxl-3">
                <div className="p-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <h6 className="fw-600 text-black">Lease paid</h6>
                  </div>

                  <div className="table-responsive transaction-data">
                    <table className="w-100">
                      <tbody>
                        {filterData?.map((item) => (
                          <tr>
                            <td>
                              <div className="trans-list d-flex align-items-center">
                                <i>
                                  <img src={Buy} alt="" />
                                </i>
                                <div className="ps-2">
                                  <h6 className="mb-1 fw-600">Paid</h6>
                                  <p className="f-12 status completed">
                                    Confirmed
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td className="text-end">
                              <h6 className="fw-600 mb-2 text-uppercase">
                                {item?.totalRedToken} red
                              </h6>
                              <p className="f-12">
                                {moment(item?.updatedAt).format("lll")}
                              </p>
                            </td>
                          </tr>
                        ))}
                        {/* {
                    <Pagination
                      color="primary"
                      count={Math.ceil(transaction?.totalItems / 8)}
                      page={page}
                      onChange={(e, v) => setPage(v)}
                      siblingCount={0}
                    />
                  } */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PossessionDetails;
