import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./FredCard.css";
import moment from "moment";
import { ethers } from "ethers";
import { fireToast } from "../../common/Toster";
import RedAbi from "../../Abis/RedAbi.json";
import FredAbi from "../../Abis/FredAbi.json";
// import { IMAGE_URL } from "../../helpers/api_helper";
import { transferNFT } from "../../services/NftServices";

const SellFredCard = ({ data, fetchData, setLoading }) => {
  const [address, setAddress] = useState("");

  const navigate = useNavigate();
  const buy = async (item) => {
    setLoading(true)
    console.log(item, "item");

    if (window.web3 && window.web3.currentProvider && window.web3.currentProvider.isCoinbaseWallet) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x89" }],
          // params: [{ chainId: '0x5' }],
        });
      } catch (e) {
        fireToast("error", "USER REJECTED THE REQUEST...");
        setLoading(false)
      }
      const provider = new ethers.providers.Web3Provider(window.web3.currentProvider);;
      await provider
        .send("eth_requestAccounts", [])
        .then(async (res) => {
          console.log(res);
          setAddress(res?.[0]);
          const signer = provider.getSigner();

          try {
            const redContractInstance = new ethers.Contract(
              "0xE3728c6330293B9B2923bd49F9DD31F47d34aaD4",
              RedAbi,
              signer
            );
            // console.log(redContractInstance,'this is fred Contract>>>>')
            const tokenDecimals = await redContractInstance.decimals();
            const balanceInHash = ethers.utils.parseUnits(
              item?.amount.toString(),
              tokenDecimals
            );

            if (balanceInHash > 0) {
              // transfer red
              redContractInstance
                .transfer(
                  "0xf6919e3f7e5E4D3441B3771Bbfd61324D5e8375c",
                  balanceInHash,
                  { gasLimit: 250000 }
                )
                .then(async (res1) => {
                  const adminPrivateKey =
                    "a3fb646246e5618b80a3eff17483d6d238b2f5dfd7d3615b3068c94d444611a0";
                  // console.log('amount transferred to the admin>>>',res)
                  const myProvider = new ethers.providers.JsonRpcProvider(
                    "https://polygon-mumbai.g.alchemy.com/v2/qRpA5Y4Fbeip7pho1zuwxPLWGdbYEhbH"
                  );

                  const adminSigner = new ethers.Wallet(
                    adminPrivateKey,
                    myProvider
                  );

                  console.log(adminSigner, "adminSigner>>>>");
                  const FREDContractAddress = item?.contractAddress;
                  const FREDCONTRACT = new ethers.Contract(
                    FREDContractAddress,
                    FredAbi,
                    adminSigner
                  );

                  console.log(FREDCONTRACT, "FREDCONTRACTFREDCONTRACT>>");
                  // transfer NFT
                  const result = await FREDCONTRACT.transferFrom(
                    "0xf6919e3f7e5E4D3441B3771Bbfd61324D5e8375c",
                    res?.[0],
                    0,
                    { gasLimit: 250000 }
                  );
                  console.log(result, "result>>>>");
                  if (result) {
                    let hash = result?.hash;
                    try {
                      const response = await transferNFT({
                        amount: item?.price,
                        nftDetailId: item?._id,
                        hashId: hash,
                        type: "fred",
                      }).then(async (res1) => {
                        console.log(item, "item");
                        fetchData();
                        setLoading(false)
                        fireToast('success','FRED buy successfully')
                        // const res = await window.ethereum.request({
                        //   method: "wallet_watchAsset",
                        //   params: {
                        //     type: "ERC721",
                        //     options: {
                        //       address: item.contractAddress,
                        //       symbol: item.fred_name?.slice(0, 3),
                        //       decimals: 0,
                        //       image: item?.nftImage,
                        //       tokenId: "0",
                        //     },
                        //   },
                        // }).then((result) => {
                        //   console.log(result,'autoimportresult')
                        // }).catch((err)=>{
                        //   console.log(err,"autoimporterror")
                        // })
                        // console.log(res, "res");
                      });

                      console.log(response, "response");
                      console.log("9");
                    } catch (error) {
                      setLoading(false)
                      console.error("Error fetching nft data:", error);
                      fireToast("error", error);
                    }
                  } else {
                    setLoading(false)
                    return;
                  }
                })
                .catch((err) => {
                  console.log(err, "error>>>");
                  fireToast(err, err?.message);
                  setLoading(false)
                });
            } else {
              // setLoading(false)
              console("consosle error");
              setLoading(false)
            }
          } catch (error) {
            fireToast("error", error);
            console.log(error);
            setLoading(false)
          }
        })
        .catch((e) => {
          console.log(e, ">>>>>>>>>>>>ERROR");
          setLoading(false)
        });
    }
  };

  return (
    <>
      <div className="fredCard">
        <div className="border rounded p-3">
          <div className="d-flex align-items-center mb-2">
            <figure className="rounded-circle mb-0 tranding-box bg-dark text-center p-2"
            style={{
              background: `url(${data?.nftImage})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}>
              {/* <img src={data?.nftImage} /> */}
            </figure>
            <h6 className="ms-2 mb-0">{data?.fred_name}</h6>
          </div>
          <figure className="tranding-img rounded position-relative bg-dark text-center"
          style={{
            background: `url(${data?.nftImage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}>
            {/* <img src={data?.nftImage} /> */}
          </figure>
          <div className="d-flex">
            <div className="me-auto">
              <h6>{data?.fred_name}</h6>
              <p className="fs-small">{moment(data?.createdAt).fromNow()}</p>
            </div>
            <p>
              Price:{" "}
              <span className="text-uppercase text-success ms-auto">
                {data?.amount} RED
              </span>
            </p>
          </div>
          <div className="d-flex">
            <div>
              <a
                style={{ cursor: "pointer" }}
                className="viewAll"
                onClick={() => navigate("/user-marketplace-fred-details", { state: {data:data} })}
              >
                View Detail
              </a>
            </div>
            {/* <button
              className="buyButton btn btn-primary btn-sm ms-auto"
              onClick={() => buy(data)}
            >
              Buy Now
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SellFredCard;
