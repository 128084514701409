import React, { useState, useEffect } from "react";
import Loader from "../components/loader/loader";
import NoData from "../components/NoDataComp/NoData";
import BuyFredCard from "../components/Fred/BuyFredCard";
import { useNavigate, Link } from "react-router-dom";
import { FaCircleChevronLeft, FaCopy } from "react-icons/fa6";
import { getFredsBuyedByUser } from "../services/NftServices";
import axios from "axios";
import { fireToast } from "../common/Toster";
import { ethers } from "ethers";

const ViewAllBuyFred = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [dbFreds, setDbFreds] = useState([]);
  const [nftOwnedByUser, setNftOwnedByUser] = useState([]);
  const [address, setAddress] = useState("");
  const [possestionsFreds, setPossestionsFreds] = useState([]);
  const [apiHit, setApiHit] = useState(false);

  const fetchBuyedFreds = async () => {
    try {
      const FredBuyedResponse = await getFredsBuyedByUser();
      if (FredBuyedResponse.status === 200) {
        setDbFreds(FredBuyedResponse?.data?.items);
        setApiHit(true);
      } else {
        throw new Error(FredBuyedResponse.error);
      }
    } catch (error) {
      console.error("Error fetching nft data:", error);
    }
  };

  const getAllNftsByUser = async () => {
    if (window.web3 && window.web3.currentProvider && window.web3.currentProvider.isCoinbaseWallet) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x89" }],
          // params: [{ chainId: '0x5' }],
        });
      } catch (e) {
        fireToast("error", "USER REJECTED THE REQUEST...");
        setLoading(false);
      }
      const provider = new ethers.providers.Web3Provider(window.web3.currentProvider);;
      await provider
        .send("eth_requestAccounts", [])
        .then(async (res) => {
          // console.log(res);
          setAddress(res?.[0]?res?.[0]:res?.result?.[0]);
          try {
            const response = await axios({
              method: "get",
              url: `https://polygon-mainnet.g.alchemy.com/nft/v2/rs8xNqu1taW0yiUL4sCgqJwSyrtpywGt/getNFTs?owner=${res?.[0]?res?.[0]:res?.result?.[0]}`,
            })
              .then(async (result1) => {
                try{
                  const response = await axios({
                    method: "get",
                    url: `https://polygon-mainnet.g.alchemy.com/nft/v2/rs8xNqu1taW0yiUL4sCgqJwSyrtpywGt/getNFTs?owner=${res?.[0]?res?.[0]:res?.result?.[0]}&pageKey=${result1?.data?.pageKey}`,
                  })
                  .then((result)=>{


                setNftOwnedByUser(result1?.data?.ownedNfts.concat(result?.data?.ownedNfts));

                let ownNfts = result1?.data?.ownedNfts.concat(result?.data?.ownedNfts);
                let myOwnNFTAddress = [];
                for (let index = 0; index < ownNfts.length; index++) {
                  const element = ownNfts[index];
                  myOwnNFTAddress.push(
                    element?.contract?.address.toLowerCase()
                  );
                }
                let myWalletFRED = [];
                for (let index = 0; index < dbFreds.length; index++) {
                  const element = dbFreds[index];
                  console.log(element, element.contractAddress, "db nft");
                  if (
                    myOwnNFTAddress.includes(
                      element.contractAddress.toLowerCase()
                    )
                  ) {
                    // console.log("true case")
                    myWalletFRED.push(element);
                  }
                }
                setPossestionsFreds(myWalletFRED);

                setTimeout(() => {
                  setLoading(false);
                }, 2000);
              })
            }
            catch (error1) {
              fireToast("error", error1);
              console.log(error1);
            }

              })



              .catch((err) => {
                console.log(err, "error fetching nfts");
                setLoading(false);
              });
          } catch (error) {
            fireToast("error", error);
            console.log(error);
          }
        })
        .catch((e) => {
          console.log(e, ">>>>>>>>>>>>ERROR");
          setLoading(false);
        });
    } else {
      fireToast("error", "Metamask not detected");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBuyedFreds();
  }, []);
  useEffect(() => {
    getAllNftsByUser();
  }, [apiHit]);

  return loading ? (
    <Loader />
  ) : (
    <div className="p-4">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Link
          className="back-btn fw-500 text-black py-4"
          onClick={() => navigate(-1)}
        >
          <i className="pe-2 align-middle">
            <FaCircleChevronLeft />
          </i>
          Back
        </Link>
      </div>

      <div className="dashboard-centerd">
        {possestionsFreds?.length > 0 ? (
          <div className="row g-0">
            <div className="row mb-4">
              {possestionsFreds?.map((item, i) => {
                return (
                  <div
                    key={i}
                    className="col-xxl-4 col-xl-4 col-md-6 col-12 mb-4"
                  >
                    <BuyFredCard
                      data={item}
                      fetchData={fetchBuyedFreds}
                      setLoading={setLoading}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
};

export default ViewAllBuyFred;
