import React, { useEffect, useState } from "react";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { useWeb3React } from "@web3-react/core";
import { FaLock } from "react-icons/fa";
import Web3, { providers } from "web3";

const ConnectWallet = () => {
  const { active, chainId, account } = useWeb3React();
  const [address,setAddress]=useState('')
  const { activate, deactivate } = useWeb3React();
  const CoinbaseWallet = new WalletLinkConnector({
    url: `https://mainnet.infura.io/v3/6a7d3c08cd8f4103bee394336fc58190`,
    appName: "Web3-react Demo",
    supportedChainIds: [1, 3, 4, 5, 42],
  });
  // console.log(account, "<><<<<<<<<<<<<<<<<");

  const connectCoinbase = async () => {
    // console.log("connecting")
    if (
      window.web3 &&
      window.web3.currentProvider &&
      window.web3.currentProvider.isCoinbaseWallet
    ) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x89" }], // chainId must be in hexadecimal numbers
        });
      } catch (e) {
        // fireToast("error", "Request Rejected.");
      }
    }
    const web3 = (window.web3 = new Web3(window.ethereum));
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    var account = accounts[0];
    setAddress(accounts[0]);
    // console.log(account, "accoutnt");
  };
useEffect(()=>{
    connectCoinbase()
},[])
  return (
    <div>
      {address ? (
        <>
          <div
            style={{ borderRadius: "8px" }}
            className="m-btn btn border-primary"
          >
            <span>
              {address.slice(0, 6)}...
              {address.slice(36, 42)}
            </span>
            {/* <FaLock style={{padding:'2px'}} onClick={deactivate} /> */}
          </div>
        </>
      ) : (
        <button
          className="btn border-primary"
          onClick={
            connectCoinbase
          }
        >
          Connect Wallet
        </button>
      )}
    </div>
  );
};

export default ConnectWallet;
