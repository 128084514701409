import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { BiChevronRight, BiSolidCalendarAlt, BiSolidMap } from "react-icons/bi";
import { Link } from "react-router-dom";
import {
  getAllNfts,
  getAllFreds,
  getUserMarketplaceNfts,
  getUserMarketplaceFreds,
  getUserTvts,
} from "../services/NftServices";
import Pagination from "@mui/material/Pagination";
import { useLocation } from "react-router-dom";
import NftCard from "../components/Nft/NftCard";
import FredCard from "../components/Fred/FredCard";
import Loader from "../components/loader/loader";
import NoData from "../components/NoDataComp/NoData";
import NoDataImage from "../images/NoDataImage.png";
import SellNftCard from "../components/Nft/SellNftCard";
import SellFredCard from "../components/Fred/SellFredCard";
import UserTvtCard from "../components/Tvt/UserTvtCard";

const Tvt = () => {
    const [loading, setLoading] = useState(true);
    const [tvtData, setTvtData] = useState([]);
    const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState("");
    const fetchTvts = async (pageNumber = page, pageSize = 12, search = "") => {
        try {
          setLoading(true);
          const response = await getUserTvts(pageNumber, pageSize, search);
          if (response.status === 200) {
            setLoading(false);
            setTvtData(response?.data?.filteredData);
            setTotalData(response?.data?.totalCount);
          } else {
            throw new Error(response.error);
          }
        } catch (error) {
          setLoading(false);
          console.error("Error fetching nft data:", error);
        }
      };

      useEffect(() => {
        fetchTvts();
      }, [page]);
    
    return loading ? (
        <Loader />
      ) : (
        <>
          <div className="ps-lg-4">
            <div className="dashboard-centerd">
              <div className="row g-0">
                <div className="col-xl-12 col-xxl-12 pe-4 pt-4 border-end center-height pb-5">
                  <div className="">
                  <div className="row border-bottom mb-4 g-0">
                          <div className="col-12 d-flex align-items-center justify-content-between mb-4">
                            <h6 className="fw-600 text-black">TVTs</h6>
                           
                          </div>
                          {tvtData.length > 0 ? (
                            <>
                          {  tvtData.map((item, i) => {
                              return (
                                <div
                                  key={i}
                                  className="col-xxl-4 col-xl-4 col-md-6 col-12 mb-4"
                                >
                                  <UserTvtCard
                                    data={item}
                                    fetchData={fetchTvts}
                                    setLoading={setLoading}
                                  />
                                </div>
                              );
                            })}
                            {
                              totalData>12&& <div
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                // minWidth: "200%",
                              }}
                            >
                              <Pagination
                                color="primary"
                                count={Math.ceil(totalData / 12)}
                                page={page}
                                onChange={(e, v) => setPage(v)}
                                // activePage={page}
                              />
                            </div>
                            }</>
                          ) : (
                            <NoData />
                          )}
                        </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
}

export default Tvt
