import React, { useState } from "react";
import logo from "../images/r-logo.svg";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { userVerifyotp,userLogin } from "../services/userService";
import { fireToast } from "../common/Toster";

const Otp = () => {
  const [otp, setOtp] = useState("");
  let navigate = useNavigate();
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: localStorage.getItem("contact-info"),
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string()
        .min(4, "Invalid otp")
        .max(4, "Invalid otp")
        .required("This Field is Required"),
      // otp: Yup.string().required("This Field is Required"),
    }),
    onSubmit: async (values) => {
      try {
        // changeApiStatus(true, "");
        console.log(values);
        const loginResponse = await userVerifyotp(values);
        if (loginResponse.status === 200) {
          console.log(loginResponse, "loginResponse");
          fireToast("success", loginResponse.message);
          localStorage.setItem("user-info", JSON.stringify(loginResponse));
          navigate("/kyc");
          // navigate("/dashboard");
        } else {
          // changeApiStatus(false, "");

          throw new Error(loginResponse.error);
        }
      } catch (err) {
        // changeApiStatus(false, "");
      }
    },
  });
  return (
    <>
      <div className="auth-bg sign-in mh-100 d-flex align-items-center justify-content-center">
        <div className="auth-card py-lg-3">
          <div className="auth-inner">
            <div className="text-center mb-4 mb-lg-5">
              <Link to="/">
                <img className="mx-auto" src={logo} alt="" />
              </Link>
              <h1 className="fs-32 mt-3">OTP Verification</h1>
              <p className="f-14">
                Please enter the OTP to begin <br /> your journey
              </p>
              <Form
                // className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Form.Group>
                  <Form.Label className="form-label text-start w-100">
                    Enter OTP sent on your email.
                  </Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      name="otp"
                      placeholder={"Enter otp"}
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.otp || ""}
                      invalid={
                        validation.touched.otp && validation.errors.otp
                          ? "true"
                          : "false"
                      }
                    />

                    {validation.touched.otp && validation.errors.otp ? (
                      // <Form.Control.Feedback type="invalid" className="text-danger">
                      //   {validation.errors.otp}
                      // </Form.Control.Feedback>
                      <div className="text-danger text-start error-msg">
                        {validation.errors.otp}
                      </div>
                    ) : null}
                  </div>
                </Form.Group>

                <div className="mt-3 d-grid">
                  <Button
                    className="common-btn mt-4 mb-3 w-100"
                    variant="primary"
                    type="submit"
                  >
                    <strong>Verify</strong>
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Otp;
