import { get, post, postAsForm,put } from "../helpers/api_helper";
import appendData from "../utils/formData";

export const getAllNfts = (pageNumber, pageSize, search) => {
  return get(
    `/client/getNftDataByUser?pageNumber=${pageNumber}&pageSize=${pageSize}&filter=${search}`
  );
};
export const getAllFreds = (pageNumber, pageSize, search) => {
  return get(
    `/fred/listFredNFT?page_number=${pageNumber}&page_limit=${pageSize}&filter=${search}`
  );
};
export const getAllTvts = (pageNumber, pageSize, search) => {
  return get(
    `/tvt/tvtDataOfSoloMarketPlace?pageNumber=${pageNumber}&pageSize=${pageSize}&filter=${search}`
  );
};
export const getAllSuperFreds= (pageNumber, pageSize, search) => {
  return get(
    `/superFred/superFredDataOfSoloMarketPlace?pageNumber=${pageNumber}&pageSize=${pageSize}&filter=${search}`
  );
};
export const getUserTvts = () => {
  return get(
    `/tvt/getUserTvt`
  );
};
export const getUserSuperFreds= (pageNumber, pageSize, search) => {
  return get(
    `/superFred/getUserSuperRed?pageNumber=${pageNumber}&pageSize=${pageSize}&filter=${search}`
  );
};
export const transferNFT = (data) => {
  return post("/fred/nftTransfer", data);
};

export const buyTvt=(data)=>{
  return post("/tvt/buyTvt", data);
}

export const buySuperFred=(data)=>{
  return post("/superFred/buySuperFred", data);
}

export const getConversionRate = () => {
  return get(`/client/buyRedToken`);
};
export const getNftsBuyedByUser = () => {
  return get(`/client/getNftDetailsOfUsers`);
};

export const getFredsBuyedByUser = () => {
  return get(`/client/getFredNftDetailsOfUsers`);
};
export const getNftCategories = (pageNumber, pageSize) => {
  return get(`/admin/getCategory?pageNumber=${pageNumber}&pageSize=${pageSize}`)
}
export const sellNftOnUserMarketplace = (data) => {
  return put(`/client/sellNftInMarketplace`,data)
}
export const sellTvtOnUserMarketplace = (data) => {
  return post(`/tvt/tvtSellInUserMarketPlace`,data)
}

export const getUserMarketplaceNfts= (pageNumber, pageSize, search) => {
  return get(`/client/getNftDetailSellInMarketplace?pageNumber=${pageNumber}&pageSize=${pageSize}&filter=${search}`)
}
export const getUserMarketplaceFreds= (pageNumber, pageSize, search) => {
  return get(`/client/getFredNftDetailSellInMarketplace?pageNumber=${pageNumber}&pageSize=${pageSize}&filter=${search}`)
}
export const getUserMarketplaceTvts= (pageNumber, pageSize, search) => {
  return get(`/tvt/getTvtInUserMarketPlace?pageNumber=${pageNumber}&pageSize=${pageSize}&filter=${search}`)
}
