import React, { useEffect, useState } from "react";
import "./SolosReward.css";
import Coins from "../../images/coins.svg";
import Solos from "../../images/newcardlogo.svg";
import Send from "../../images/send.svg";
import Button from "react-bootstrap/Button";
import Recive from "../../images/recive.svg";
import Buy from "../../images/buy.svg";
import SendSr from "../../components/Modals/SendSr";
import { walletData } from "../../services/propertyServices";
import { useNavigate } from "react-router-dom";
import { fireToast } from "../../common/Toster";
import SrTokenAbi from "../../Abis/SrAbi.json";
import { getSrPriceInUsd } from "../../services/propertyServices";
import Buyicon from "../../images/buy.svg";
import Web3, { providers } from "web3";
import JSBI from "jsbi";
const SolosReward = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({ redToken: 0, usd: 0 });
  const fetchWalletData = async () => {
    const wallet = await walletData();
    setData(wallet?.data);
  };
  const [balance, setBalance] = useState("");
  const getWallet = async () => {
    if (
      window.web3 &&
      window.web3.currentProvider &&
      window.web3.currentProvider.isCoinbaseWallet
    ) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x89" }], // chainId must be in hexadecimal numbers
        });
      } catch (e) {
        // fireToast("error", "Request Rejected.");
      }
    }
    const web3 = (window.web3 = new Web3(window.ethereum));
    var contractInstance = "";
    contractInstance = new web3.eth.Contract(
      SrTokenAbi,
      "0xB7Ed63316fa41A1EEfd5E31a81Ab3144FE51F4C4",
      providers
    );
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    var account = accounts[0];
    const RedBalance = await contractInstance._methods
      .balanceOf(account)
      .call();
    const srBalanceFormat = JSBI.divide(
      JSBI.BigInt(Number(RedBalance)),
      JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(18))
    ).toString();
    setBalance(srBalanceFormat);
  };
  const [priceinUsd, setPriceinUsd] = useState(0);
  useEffect(() => {
    getWallet();
    fetchWalletData();
    getSrPriceInUsd().then((Res) => {
      setPriceinUsd(Res?.data?.conversionrate);
    });
  }, []);
  function formatCompactNumber(number) {
    const formatter = Intl.NumberFormat("en", { notation: "compact" });
    return formatter.format(number);
  }
  return (
    <>
      <div
        className="solos-card d-flex flex-column justify-content-between"
        style={{ cursor: "pointer" }}
        // onClick={() => navigate("/accounts")}
      >
        <div className="token-head d-flex align-items-center justify-content-between">
          <div>
            <span className="text-white fw-600">Solos Reward</span>
            <h6 className="fs-24 text-white">{balance}</h6>
          </div>
          <div className="text-end">
            <span className="text-white fw-600"> USD</span>
            <h6 className="fs-24 text-white">{balance / priceinUsd}</h6>
          </div>
        </div>
        <div className="token-bottom d-flex align-items-end justify-content-between">
          <div className="d-flex align-items-center text-center">
            <a href="#">
              {/* <span className="token-btn d-flex align-items-center justify-content-center mx-auto mb-1">
                <img src={Send} alt="" />
              </span>
              <span className="text-center fs-8 mb-0 text-white fw-500">
                Send
              </span> */}
              <span className="text-center fs-8 mb-0 text-white fw-500">
                {" "}
                <SendSr getWallet={getWallet} />
              </span>
            </a>
            <a className="mx-3" href="#">
              <span className="token-btn d-flex align-items-center justify-content-center mx-auto mb-0 receive-tkn position-relative">
                <img src={Recive} alt="" />
              </span>
              <span className="text-center fs-8 mb-0 text-white fw-500">
                Receive
              </span>
            </a>
            <Button
              className="bg-transparent border-0 p-0"
              variant="primary"
              onClick={() => navigate("/marketplace")}
            >
              <span className="token-btn d-flex align-items-center justify-content-center mx-auto mb-1">
                <img src={Buyicon} alt="" />
              </span>
              Buy
            </Button>
          </div>
          <div>
            <img src={Solos} alt="" height={24} />
          </div>
        </div>
      </div>
    </>
  );
};

export default SolosReward;
