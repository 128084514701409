import React from "react";
import "../../HomePage/style.css";
import { Col, Container, Row } from "react-bootstrap";
import lease from "../../../images/lease.png";

const Propertylease = () => {
  return (
    <>
      <div className="property-wrapper py-lg-5" id="about_us">
        <Container>
          <Row>
            <Col>
              <h2 className="f-64 py-5">
                We’re Building a <span>Different</span> <br /> Kind of Company
              </h2>
            </Col>
          </Row>

          <Row className="align-items-center py-5">
            <Col lg={{ order: 1, span: 6, offset: 1 }}>
              <div className="f-56 mb-4 d-lg-none d-block">Property Leasing</div>
              <img src={lease} alt="" />
            </Col>
            <Col className="pe-xxl-5 mt-lg-0 mt-4">
              <div className="f-56 mb-4 d-lg-block d-none">
                Property Leasing
              </div>
              <p className="f-18 pe-xxl-5">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Propertylease;
