import { useState, useEffect } from "react";
import "./Modal.css";
import { FaCircleChevronLeft, FaCopy } from "react-icons/fa6";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import send from "../../images/send.svg";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import {
  SendRed,
  transaction,
  getAllClientUsers,
} from "../../services/propertyServices";
import { fireToast } from "../../common/Toster";
import Loader from "../loader/loader";
import RedTokenAbi from "../../Abis/RedTokenAbi.json";
import Web3 from "web3";
import { BigNumber } from "ethers";
import { Signer, ethers } from "ethers";
import { getContractInstance } from "../../web3/web3";
import { redAddress } from "../../Contracts/usdc";
const Receive = ({ getWallet }) => {
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [search1, SetSearch] = useState("");
  const [userData, setUserData] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const SignupSchema = Yup.object().shape({
    userName: Yup.string().required("User name is required"),
    redToken: Yup.string().required("Amount is required"),
    walletAddress: Yup.string()
      .min("42")
      .max("42")
      .required("Address is required"),
  });

  const onFormSubmit = async (data, actions) => {
    setLoader(true);
    sendCustomAssets(data);
  };

  // wallet
  const sendCustomAssets = async (
    // userToken = "0xf6919e3f7e5E4D3441B3771Bbfd61324D5e8375c",
    // amount = 10
    data
  ) => {
    try {
      setLoader(true);
      if (
        window.web3 &&
        window.web3.currentProvider &&
        window.web3.currentProvider.isCoinbaseWallet
      ) {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [
            {
              chainId: `${"0x89"}`,
            },
          ],
        });
      }
      const web3 = (window.web3 = new Web3(window.ethereum));

      const walletAddress = await window.ethereum.send("eth_requestAccounts");
      const provider = new ethers.providers.Web3Provider(
        window.web3.currentProvider
      );
      const signer = provider.getSigner(walletAddress[0]);
      // console.log(signer, "signer");

      // var contractInstance = "";
      // contractInstance = new web3.eth.Contract(
      //   RedTokenAbi,
      //   "0xE3728c6330293B9B2923bd49F9DD31F47d34aaD4"
      // );

      // const newAmount = web3.utils.toHex(
      //   possessions?.propertyDetails?.MonthlyFees * 10 ** 8
      // );

      // const newAmount = (data.redToken * 10 ** 18).toString(); //static value for testing and origional code hide in top
      const newAmount = BigNumber.from(data.redToken).mul(BigNumber.from(10).pow(18))

      const instance = await getContractInstance(
        redAddress,
        RedTokenAbi,
        Signer
      );
      // console.log(instance, "instance123");
      // const tx = {
      //   from: walletAddress[0],
      //   to: contractInstance._address,
      //   data: contractInstance.methods
      //     .transfer(data.walletAddress, newAmount,  { gasLimit: 250000 })
      //     .encodeABI(),
      // };

      const approveReponse = await instance.transfer(
        data?.walletAddress,
        newAmount,
        {
          gasLimit: 300000,
        }
      );

      // web3.eth
      //   .sendTransaction(tx)
      // approveReponse().then((res) => {
      //     getWallet()
      //     setLoader(false);
      //     transaction({walletAddress:data.walletAddress,transactionType:"Red Token",token:data.redToken,userName:data.userName,hashId:res.transactionHash}).then((res)=>{
      //       console.log(res,"res")
      //     })
      //     fireToast("success", "Successfully Sent.");
      //     handleClose();
      //   })
      //   .catch((err) => {
      //     setLoader(false);
      //     console.log("err", err);
      //     fireToast("error", err?.message);
      //   });

      const response = await approveReponse.wait();
      console.log(response);
      if (response.status) {
        getWallet();
        setLoader(false);
        transaction({
          walletAddress: data.walletAddress,
          transactionType: "Red Token",
          token: data.redToken,
          userName: data.userName,
          hashId: response.transactionHash,
        }).then((res) => {
          console.log(res, "res");
        });
        fireToast("success", "Successfully Sent.");
        handleClose();
      }
    } catch (err) {
      setLoader(false);
      fireToast("error", err);
      console.log(err, "try error");
    }
  };
  const fetchUserData = async (
    pageNumber = 1,
    pageSize = 5,
    search = search1
  ) => {
    try {
      const list = await getAllClientUsers(pageNumber, pageSize, search);
      if (list.status === 200) {
        setUserData(list?.data?.items);
        console.log(list?.data?.items, "listss");
      } else {
        throw new Error(list.error);
      }
    } catch (err) {}
  };

  useEffect(() => {
    fetchUserData();
  }, [search1]);
  //end wallet

  const handleSearch = (value) => {
    SetSearch(value);
    console.log(value, "test values");
    fetchUserData();
  };

  return (
    <>
      <Button
        className="bg-transparent border-0 p-0"
        variant="primary"
        onClick={handleShow}
      >
        <span className="token-btn d-flex align-items-center justify-content-center mx-auto mb-1">
          <img src={send} alt="" />
        </span>
        Send
      </Button>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Body>
          <span
            className="back-btn fw-500 text-black"
            onClick={() => handleClose()}
          >
            <i className="pe-2 align-middle">
              <FaCircleChevronLeft />
            </i>
            Send RED Token
          </span>
          <div className="px-5 my-4">
            <Formik
              initialValues={{
                userName: "",
                walletAddress: "",
                redToken: "",
                accountType: "Primary",
              }}
              validationSchema={SignupSchema}
              onSubmit={(values, actions) => {
                setTimeout(() => {
                  onFormSubmit(values, actions);
                  actions.setSubmitting(false);
                }, 500);
              }}
            >
              {({ handleSubmit, errors, touched, setFieldValue }) => (
                <Form onSubmit={handleSubmit} className="row user-form">
                  <Form.Group
                    className="col-12 mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Enter User Name</Form.Label>
                    <Field
                      onChange={(e) => {
                        setFieldValue("userName", e.target.value);
                        handleSearch(e.target.value);
                      }}
                      autoComplete="off"
                      type="text"
                      placeholder="Enter User Name"
                      name="userName"
                      className="form-ctl"
                    />
                      {search1 == '' ? (
                            ''
                          ) : (
                            <div className="customSearch">
                              {userData.length == 0 ? (
                                <div> No Data Found. </div>
                              ) : (
                                userData?.map((item, i) => (
                                  <div
                                    onClick={() => {
                                      setFieldValue('walletAddress', item?.walletAddress)
                                      setFieldValue('userName', item?.userName)
                                      SetSearch('')
                                    }}
                                    className="items"
                                    key={i}
                                  >
                                    <div
                                      style={{
                                        padding: '5px 0px',
                                        borderBottom: '1px solid #2c29294d',
                                      }}
                                    >
                                      {item?.userName}
                                    </div>
                                  </div>
                                ))
                              )}
                            </div>
                          )}
                    {errors.userName && touched.userName ? (
                      <div className="text-danger">{errors.userName}</div>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="col-12 mb-4" controlId="">
                    <Form.Label>Enter Wallet Address</Form.Label>
                    <Field
                      type="text"
                      placeholder="Enter Wallet Address"
                      name="walletAddress"
                      className="form-ctl"
                    />
                    {errors.walletAddress && touched.walletAddress ? (
                      <div className="text-danger">{errors.walletAddress}</div>
                    ) : null}
                  </Form.Group>

                  <Form.Group className="col-12 mb-4" controlId="">
                    <Form.Label>Enter RED Amount</Form.Label>
                    <Field
                      type="text"
                      placeholder="Enter amount"
                      name="redToken"
                      className="form-ctl"
                    />
                    {errors.redToken && touched.redToken ? (
                      <div className="text-danger">{errors.redToken}</div>
                    ) : null}
                  </Form.Group>

                  <div className="col-12">
                    {loader ? (
                      <Button
                        // disabled={loader}
                        className="w-100 common-btn"
                        variant="primary"
                      >
                        <span className="typeWriter">
                          processing<span> . . . . .</span>
                        </span>
                      </Button>
                    ) : (
                      <Button
                        // disabled={loader}
                        className="w-100 common-btn"
                        variant="primary"
                        type="submit"
                      >
                        Send
                      </Button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Receive;
