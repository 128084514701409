import React from "react";
import "../../HomePage/style.css";
import "../Testimonials/Testimonials.css";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import client from "../../../images/client.png";
import Quotes from "../../../images/q.svg";

const Testimonials = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <div className="py-5 mb-lg-5" id="testimonials">
        <Container>
          <Row>
            <Col xs={12}>
              <h1 class="f-64 mb-5">Testimonials</h1>
            </Col>
            <Col xs={12}>
              <Slider {...settings}>
                <div>
                  <Row className="align-items-center">
                    <Col className="relative mb-md-0 mb-4 text-center" md={3} lg={2}>
                      <img className="mx-auto" src={client} alt="" />
                      <span className="quotes">
                        <img src={Quotes} alt="" />
                      </span>
                    </Col>
                    <Col md={9} lg={{ span: 9, offset: 1 }}>
                      <div className="feedback-content">
                        <p className="f-40 mb-2">
                          When someone tells you it can’t be done, it’s more a
                          reflection of their limitations, not yours!
                        </p>
                        <h3 className="f-18 mb-1">
                          <strong>Chris Magnum</strong>
                        </h3>
                        <p className="f-14">ABC</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Testimonials;
