import React from "react";
import Dashlogo from "../images/dash-logo.svg";
import Logoicon from "../images/smallLogo.svg";
import DashOne from "../images/icon1.svg";
import DashTwo from "../images/icon2.svg";
import DashThree from "../images/icon3.svg";
import DashFour from "../images/icon4.svg";
import DashFive from "../images/icon5.svg";
import { NavLink } from "react-router-dom";
import Sidebarpaths from "../routes/SidebarPaths";

const DashboardLeft = () => {
  return (
    <>
      <div className="left-sidebar">
        <a className="dash-logo" href="/">
          <img className="big-logo" src={Dashlogo} alt="" />
          <img className="small-icon" src={Logoicon} alt="" />
        </a>
        <ul className="dash-pages">
          <li>
            {Sidebarpaths.map((path, id) => (
              <NavLink
                key={id}
                to={path.path}
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
              >
                <i className="me-2" title={path.title}>
                  <img src={path.icon} alt="" />
                </i>
                <span>{path.title}</span>
              </NavLink>
            ))}
          </li>
        </ul>
      </div>
    </>
  );
};

export default DashboardLeft;
