import React, { useEffect, useState } from "react";
import "./Modal.css";
import { Signer, ethers } from "ethers";
import { FaCircleChevronLeft, FaCopy } from "react-icons/fa6";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import Buyicon from "../../images/buy.svg";
import usdcAbi from "../../Contracts/usdc.json";
import { BigNumber } from "ethers";
import RedAbi from '../../../src/Abis/RedAbi.json'
import {
  getConversionRate,
  requestRedToken,
} from "../../services/propertyServices";
import { redAddress } from "../../Contracts/usdc";
import Web3 from "web3";
import { getContractInstance, getConvertedAmount } from "../../web3/web3";
import { fireSuccessToast, fireToast } from "../../common/Toster";
import send from "../../images/send.svg";

const Sell = () => {
  const [loader , setLoader]=useState(false)
  const [show, setShow] = useState(false);
  const [userAddress, setUserAddress] = useState(null);
  const [amount, setAmount] = useState("");
  const [usdcToken, setUsdcToken] = useState("");
  const [walletAddress, setWalletAddress] = useState(0);
  const [adminAddress, setAdminAddress] = useState(0);
  const [conversionRate, setConversionRate] = useState(0);
  const [commissionPercentage, setCommissionPercentage]=useState(0);

  const handleClose = () => setShow(false);

  const handleShow = async () => {
    const exChangeRate = await getConversionRate();
    const { conversionrate, adminWalletAddress , sellpercentage, receivewalletaddress } = exChangeRate.data;
    setAdminAddress(receivewalletaddress);
    setConversionRate(conversionrate);
    setCommissionPercentage(sellpercentage)
    setShow(true);
    const res = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    setUserAddress(res[0]);
  };

  async function SendTransactionToAdmin() {
    if (amount <= 0) {
      fireToast("error", "please enter amount...");
    } else {
      try {
        setLoader(true)
        // const amountToBePassed = await getConvertedAmount(amount, 6);
        // const amountToBePassed = (amount * 10 ** 18).toString();
        const amountToBePassed = BigNumber.from(amount).mul(BigNumber.from(10).pow(18))


        //   const amount = ethers.parseUnits("100000000", 6);
        // console.log(Number(amount),"amountamount");

        console.log(amountToBePassed, "amount");

        const instance = await getContractInstance(
          redAddress,
          RedAbi,
          Signer
        );
        console.log(instance, "instance");

        const approveReponse = await instance.transfer(
          '0xb48c3fF77886fEF07384f3215B0ed9268908bC8B',
          amountToBePassed,
          {
            gasLimit: 300000,
          }
        );

        const response = await approveReponse.wait();
        if (response.status) {
          const requestData = {
            accountType: "PRIMARY",
            transactionType: "Sell RED",
            // usdc: usdcToken - (usdcToken * 1 /100).toString(),
            usdc: usdcToken ,
            hashId:response.transactionHash,
            hashId2: "",
            totalRedToken: amount,
            walletAddress: walletAddress
          };
          await requestRedToken(requestData).then(
            (response) => {
              setLoader(false)
              console.log(response);
              handleClose()
              fireSuccessToast('Successfully Done!')
            },
            (error) => {
              setLoader(false)
              console.log(error);
            }
          );
        }
      } catch (error) {
        setLoader(false)
        console.log(error);
      }
    }
  }

  const getWallet = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x89" }], // chainId must be in hexadecimal numbers
        });
      } catch (e) {
        // fireToast("error", "Request Rejected.");
      }
    }
    const web3 = (window.web3 = new Web3(window.ethereum));
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        var account = accounts[0];
        setWalletAddress(accounts[0])


  };

  useEffect(()=>{
    getWallet()
  },[])

  return (
    <>
      <Button
        className="bg-transparent border-0 p-0"
        variant="primary"
        onClick={handleShow}
      >
        <span className="token-btn d-flex align-items-center justify-content-center mx-auto mb-1">
          <img src={send} alt="" />
        </span>
        Sell
      </Button>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Body>
          <Link
            className="back-btn fw-500 text-black"
            onClick={() => setShow(false)}
          >
            <i className="pe-2 align-middle">
              <FaCircleChevronLeft />
            </i>
            Sell RED
          </Link>
          <div className="px-5 my-4">
            <Form className="row user-form">
              <Form.Group className="col-12 mb-3" controlId="formBasicEmail">
                <Form.Label>Enter RED Amount</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="0"
                  onChange={(e) => {
                    setAmount(e.target.value);
                    setUsdcToken(e.target.value / conversionRate);
                  }}
                  disabled={conversionRate == 0}
                  value={amount}
                />
              </Form.Group>

              <Form.Group className="col-12 mb-4" controlId="">
                <Form.Label>Enter USDC Tokens </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="0"
                  value={usdcToken }
                  // - (Number(usdcToken) * Number(commissionPercentage) /100)
                  disabled
                />
              </Form.Group>
              <Form.Group className="col-12 mb-4" controlId="">
                <Form.Label>Enter Wallet Address </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Wallet address.."
                  value={walletAddress}
                  onChange={(e) => {
                    setWalletAddress(e.target.value);
                  }}
                />
              </Form.Group>

              <div className="col-12">
               


                {loader? 
                  <Button
                  // disabled={loader}
                  className="w-100 common-btn"
                  variant="primary"
                >
               <span className="typeWriter">processing<span> . . . . .</span></span>
                </Button>
                 : <Button
                 className="w-100 common-btn"
                 variant="primary"
                 disabled={amount <= 0 && !userAddress}
                 onClick={SendTransactionToAdmin}
               >
                 Sell
               </Button>}
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Sell;
