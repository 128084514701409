import React, { useState } from "react";
import "./NftCard.css";
import moment from "moment";
// import { IMAGE_URL } from "../../helpers/api_helper";
import BuyNftAbi from "../../Abis/BuyNftAbi.json";
import { ethers } from "ethers";
import AdminContractABI from "../../Abis/AdminContractAbi.json";
import { useNavigate } from "react-router-dom";
import { fireToast } from "../../common/Toster";
import { transferNFT } from "../../services/NftServices";

function capitalize(string) {
  return string
    ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    : null;
}
const SellNftCard = ({ data, fetchData, setLoading }) => {
  const navigate = useNavigate();
  const [address, setAddress] = useState("");

  const buy = async (item) => {
    setLoading(true)
    //admin nft work
    const adminPrivateKey =
      "a3fb646246e5618b80a3eff17483d6d238b2f5dfd7d3615b3068c94d444611a0"; //admin pvt key
    const maticProvider = new ethers.providers.JsonRpcProvider(
      "https://polygon-mumbai.g.alchemy.com/v2/qRpA5Y4Fbeip7pho1zuwxPLWGdbYEhbH"
    );
    const adminSigner = new ethers.Wallet(adminPrivateKey, maticProvider);

    const adminContractAddress = item.contractAddress;
    const adminContract = new ethers.Contract(
      adminContractAddress,
      AdminContractABI,
      adminSigner
    );
    const tokenDecimals = "8";
    const balanceInHash = ethers.utils.parseUnits(
      item?.price.toString(),
      tokenDecimals
    );

    if (window.web3 && window.web3.currentProvider && window.web3.currentProvider.isCoinbaseWallet) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x89" }],
          // params: [{ chainId: '0x5' }],
        });
      } catch (e) {
        fireToast("error", "USER REJECTED THE REQUEST...");
        setLoading(false)
      }
      const provider = new ethers.providers.Web3Provider(window.web3.currentProvider);;
      await provider
        .send("eth_requestAccounts", [])
        .then(async (res) => {
          console.log(res);
          setAddress(res?.[0]);
          const signer = provider.getSigner();
          try {
            const REDContractAddress =
              "0xE3728c6330293B9B2923bd49F9DD31F47d34aaD4";
            const REDContractInstance = new ethers.Contract(
              REDContractAddress,
              BuyNftAbi,
              signer
            );
            await REDContractInstance.transfer(
              "0xf6919e3f7e5E4D3441B3771Bbfd61324D5e8375c",
              balanceInHash,
              { gasLimit: 250000 }
            )
              .then(async (result) => {
                console.log(result, "resultRED");
                console.log(address, "address");
                await adminContract
                  .transferFrom(
                    "0xf6919e3f7e5E4D3441B3771Bbfd61324D5e8375c",
                    res?.[0],
                    0,
                    { gasLimit: 250000 }
                  )
                  .then(async (responseNFT) => {
                    console.log(responseNFT, "resultNFT");
                    try {
                      const response = await transferNFT({
                        amount: item?.price,
                        nftDetailId: item?._id,
                        hashId: responseNFT?.hash,
                        type: "normal",
                      }).then(async (res1) => {
                        console.log(item, "item");
                        setLoading(false)
                        fireToast("success", "NTF buy successfully");
                        fetchData();
                        
                        // const res = await window.ethereum.request({
                        //   method: "wallet_watchAsset",
                        //   params: {
                        //     type: "ERC721",
                        //     options: {
                        //       address: item?.contractAddress,
                        //       symbol: item?.symbol,
                        //       decimals: 0,
                        //       image: `https://foo.io/token-image.svg`,
                        //       tokenId: "1",
                        //     },
                        //   },
                        // }).then((result) => {
                        //   console.log('result??????')
                        //   console.log(result,'autoimportresult')
                        // }).catch((err)=>{
                        //   console.log(err,"autoimporterror")
                        // })
                        // console.log(res, "res");
                      }).catch((errApi)=>{
                        setLoading(false)
                        fireToast("error", errApi);
                      })
                    } catch (error) {
                      console.error("Error fetching nft data:", error);
                      setLoading(false)
                      fireToast("error", error);
                    }
                  })
                  .catch((err) => {
                    setLoading(false)
                    console.log(err, "errorNFT");
                    fireToast("error", err);
                  });
              })
              .catch((e) => {
                console.log(e.message, "errorRED");
                setLoading(false)
                fireToast(
                  "error",
                  e.message
                    ? e.message.includes("user rejected transaction")
                      ? "User Rejected Transaction"
                      : "Error"
                    : "Something Went Wrong, Please try again." 
                );
              });

            // if (data) {
            //   let hash = data?.hash;
            //   // transferNFT(item, hash,'normal')
            // } else {
            //   return;
            // }
          } catch (error) {
            fireToast("error", error);
            console.log(error);
            setLoading(false)
          }
        })
        .catch((e) => {
          setLoading(false)
          console.log(e, ">>>>>>>>>>>>ERROR");
        });
    }else{
      setLoading(false)
      fireToast("error", "Metamask not detected");
    }
  };

  return (
    <>
      <div className="nftCard">
        <div className="border rounded p-3">
          <div className="d-flex align-items-center mb-2">
            <figure className="rounded-circle mb-0 tranding-box bg-dark text-center p-2"
            style={{
              background: `url(${data?.Image})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
            >
              {/* <img src={data?.Image} /> */}
            </figure>
            <h6 className="ms-2 mb-0">{capitalize(data?.name)}</h6>
          </div>
          <figure className="tranding-img rounded position-relative bg-dark text-center"
          style={{
            background: `url(${data?.Image})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}>
            {/* <img src={data?.Image} /> */}
          </figure>
          <div className="d-flex">
            <div className="me-auto">
              <h6>{capitalize(data?.name)}</h6>
              <p className="fs-small">{moment(data?.createdAt).fromNow()}</p>
            </div>
            <p>
              Price:{" "}
              <span className="text-uppercase text-success ms-auto">
                {data?.price} RED
              </span>
            </p>
          </div>
          <div className="d-flex">
            <div>
              <a
                style={{ cursor: "pointer" }}
                className="viewAll"
                onClick={() => navigate("/user-marketplace-nft-details", { state:{data: data} })}
              >
                View Detail
              </a>
            </div>
            {/* <button
              className="buyButton btn btn-primary btn-sm ms-auto"
              onClick={() => buy(data)}
            >
              Buy Now
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SellNftCard;
