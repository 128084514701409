import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useRoutes,
} from "react-router-dom";
import { UseRoute } from "./routes/route";
import { apiHeader } from "./helpers/api_helper";
// import { getFirebaseToken, onForegroundMessage } from "./firebase/firebase";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import ConversionContext from "./Context/context";
function App() {
  // const [showNotificationBanner, setShowNotificationBanner] = useState(
  //   Notification.permission === "default"
  // );
  document.title = "SOLOS"
  apiHeader();
  // useEffect(() => {
  //   onForegroundMessage()
  //     .then((payload) => {
  //       console.log("Received foreground message: ", payload);
  //       const {
  //         notification: { title, body },
  //       } = payload;
  //       toast(<ToastifyNotification title={title} body={body} />);
  //     })
  //     .catch((err) =>
  //       console.log(
  //         "An error occured while retrieving foreground message. ",
  //         err
  //       )
  //     );
  // }, []);

  // const handleGetFirebaseToken = () => {
  //   getFirebaseToken()
  //     .then((firebaseToken) => {
  //       console.log('Firebase token: ', firebaseToken);
  //       if (firebaseToken) {
  //         setShowNotificationBanner(false);
  //       }
  //     })
  //     .catch((err) => console.error('An error occured while retrieving firebase token. ', err))
  // }

  const ToastifyNotification = ({ title, body }) => (
    <div className="push-notification">
      <h2 className="push-notification-title">{title}</h2>
      <p className="push-notification-text">{body}</p>
    </div>
  );
  const auth = localStorage.getItem("user-info");
  let element = useRoutes(UseRoute(auth));
  // handleGetFirebaseToken()
  return (
    <>
      <ConversionContext>{element}</ConversionContext>
    </>
  );
}

export default App;
