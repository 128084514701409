import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// import { IMAGE_URL } from "../../helpers/api_helper";
import "./SuperFredDetail.css";
import moment from "moment";
import RedAbi from "../../Abis/RedAbi.json";
import { Signer, ethers } from "ethers";
import FredAbi from "../../Abis/FredAbi.json";
import { BigNumber } from "ethers";
import { FaCalendar, FaClock } from "react-icons/fa";
import { ConversionContext } from "../../Context/context";
import { FaCircleChevronLeft, FaCopy } from "react-icons/fa6";
import { AiOutlineClose } from "react-icons/ai";
import Button from "react-bootstrap/Button";
import {
  getConversionRate,
  requestRedToken,
} from "../../services/propertyServices";
import { useNavigate } from "react-router-dom";
import { getContractInstance, getConvertedAmount } from "../../web3/web3";
import { redAddress } from "../../Contracts/usdc";
import { Link } from "react-router-dom";
import Loader from "../loader/loader";
import { fireToast } from "../../common/Toster";
import {
  transferNFT,
  sellNftOnUserMarketplace,
  buySuperFred,
} from "../../services/NftServices";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Web3 from "web3";
function capitalize(string) {
  return string
    ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    : null;
}
const SuperFredDetail = () => {
  const [address, setAddress] = useState("");
  const [openBuy, setOpenBuy] = useState(false);
  const [totalRedAmount, setTotalRedAmount] = useState("");
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const contextData = useContext(ConversionContext);
  const [adminAddress, setAdminAddress] = useState(0);
  const location = useLocation();
  const data = location?.state?.data;
  const card = location?.state?.card;
  const conversionRate = contextData?.conversionRate;
  console.log(data, "data");
  const handleClose = () => setShow(false);
  const handleBuyClose = () => setOpenBuy(false);

  const [WalletAddress, setWalletAddress]=useState("")
  const getWallet = async () => {
    if (window.web3 && window.web3.currentProvider && window.web3.currentProvider.isCoinbaseWallet) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x89" }], // chainId must be in hexadecimal numbers
        });
      } catch (e) {
        // fireToast("error", "Request Rejected.");
      }
    }
    const web3 = (window.web3 = new Web3(window.ethereum));
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        var account = accounts[0];
        setWalletAddress(accounts[0])
  console.log(account,"account>>>>>");
  }
useEffect(()=>{
  getWallet();
},[])

  const Schema = Yup.object().shape({
    amount: Yup.string().required("Please enter FRED Amount"),
  });
  const BuySchema = Yup.object().shape({
    quantity: Yup.number()
      .required("Please enter Quantity")
      .max(data?.limit, `Your purchase limit is ${data?.limit} SFREDs`),
  });
 
  const onSubmit = async (values) => {
    console.log(values, "vals");
    try {
      setLoading(true);
      const response = await sellNftOnUserMarketplace({
        price: values?.amount,
        nftId: values?.nftId,
        type: "fred",
      })
        .then(async (res) => {
          setLoading(false);
          fireToast("success", "NFT transfered to marketplace");
          navigate("/marketplace", { state: { data: "usermarket" } });
        })
        .catch((errApi) => {
          setLoading(false);
          fireToast("error", errApi);
        });
    } catch (error) {
      console.error("Error fetching nft data:", error);
      setLoading(false);
      fireToast("error", error);
    }
  };

  const onBuy = async (values) => {
    if (values?.quantity > data?.quantity) {
      fireToast("error", `Only ${data?.quantity} SFREDs available`);
    } else {
      try {
        setLoading(true);
        // const amountToBePassed = await getConvertedAmount(amount, 6);
        // const amountToBePassed = (
        //   values?.quantity *
        //   data?.price *
        //   10 ** 8
        // ).toString();
        const amountToBePassed = BigNumber.from(values?.quantity*data?.price).mul(BigNumber.from(10).pow(18))

        //   const amount = ethers.parseUnits("100000000", 6);
        // console.log(Number(amount),"amountamount");

        console.log(amountToBePassed, "amount");

        const instance = await getContractInstance(redAddress, RedAbi, Signer);
        console.log(instance, "instance");

        const approveReponse = await instance.transfer(
          adminAddress,
          amountToBePassed,
          {
            gasLimit: 300000,
          }
        );
        const response = await approveReponse.wait();
        if (response.status) {
          try {
            setLoading(true);
            const response = await buySuperFred({
              quantity: values?.quantity,
              id: data?._id,
              dateOfMaturity: data?.dateOfMaturity,
              series: data?.series,
              limit: data?.limit, 
              description: data?.description,
              price: data?.price,
              image: data?.image,
              maturityAmount: data?.maturityAmount,
              rewardDistribution: data?.rewardDistribution,
              rewardSR: data?.rewardSR,
              type: data?.type,
              walletAddress: WalletAddress,
            })
              .then(async (res) => {
                setLoading(false);
                fireToast("success", "Super FRED buy successfully");
                navigate("/marketplace");
              })
              .catch((errApi) => {
                setLoading(false);
                fireToast("error", errApi);
              });
          } catch (error) {
            console.error("Error fetching nft data:", error);
            setLoading(false);
            fireToast("error", error);
          }
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };

  const buy = async () => {
    const exChangeRate = await getConversionRate();
    const { conversionrate, adminWalletAddress } = exChangeRate.data;
    setAdminAddress(adminWalletAddress);
    setOpenBuy(true);
  };

  return loading ? (
    <Loader />
  ) : (
    <div className="SuperFredDetail ps-4">
      <Link
        className="back-btn fw-500 text-black pt-4"
        onClick={() => navigate(-1)}
      >
        <i className="pe-2 align-middle">
          <FaCircleChevronLeft />
        </i>
        Back
      </Link>
      <div className="dashboard-centerd">
        <div className="row g-0">
          <div className="col-xl-12 col-xxl-12 pe-4 pt-4 border-end center-height pb-5">
            <div className="row mb-5">
              <div className="col-6 sideImage">
                <figure
                  className="mb-0 imageFigure"
                  style={{
                    background: `url(${data?.image})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  {/* <img src={data?.nftImage} /> */}
                </figure>
              </div>
              <div className="col-6 sideText">
                <div className="mainText mb-3">
                  <h4 style={{ color: "#000" }}>{data?.series}</h4>
                  <h6 className="fw-bold" style={{ color: "green" }}>
                    {data?.price} RED {`($${data?.price / conversionRate})`}
                  </h6>
                </div>
                <div className="descText">
                  <h6 style={{ color: "#000" }}>Description</h6>
                  <p>{data?.description}</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 collectionDetails">
                <div className="p-3 mintNowText">
                  <h6 style={{ color: "#000" }} className="mb-3">
                    {card == "sell"
                      ? "Sell your FRED now on user-marketplace"
                      : "Buy now to get your Super FRED"}
                  </h6>
                  <h6 style={{ color: "#000" }} className="mb-3">
                    {data?.price} RED
                  </h6>

                  {card == "sell" ? (
                    <div className="mintButton">
                      <button
                        className="btn"
                        // onClick={() => setShow(true)}
                      >
                        Sell on User-Marketplace
                      </button>
                    </div>
                  ) : (
                    <div className="mintButton">
                      <button className="btn" onClick={() => buy()}>
                        Buy Now
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Header>
          <h5 className="" style={{ margin: "auto" }}>
            {" "}
            Sell NFT on user marketplace
          </h5>
          <span
            style={{ cursor: "pointer" }}
            className=" d-flex justify-content-end mb-2"
            onClick={handleClose}
          >
            <AiOutlineClose />
          </span>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                tradePrice: data?.tradePrice,
              }}
              validationSchema={Schema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ errors, touched, values, setFieldValue, setTouched }) => (
                <Form className="sellNFT-form">
                  <div className="row align-items-start justify-content-center">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label htmlFor="propertyName">FRED Name:</label>
                          <Field
                            disabled
                            name="fred_name"
                            id="fred_name"
                            placeholder="Enter FRED Name"
                            className="form-control"
                          />
                          {errors.fred_name && touched.fred_name ? (
                            <div className="input-error">
                              {errors.fred_name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            className="uploadImageContainern d-flex justify-content-center mb-4"
                            style={{
                              borderRadius: "11px",
                              border: "1px solid lightgray",
                            }}
                          >
                            <img
                              src={data?.nftImage}
                              alt="Avatar"
                              className="image"
                              style={{
                                height: "100px",
                                objectFit: "contain",
                                width: "100px",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <Button
                        className="w-100 common-btn1"
                        variant="primary"
                        type="submit"
                      >
                        Sell
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={openBuy}
        onHide={handleBuyClose}
        animation={false}
      >
        <Modal.Header>
          <h5 className="" style={{ margin: "auto" }}>
            {" "}
            Buy Super FRED
          </h5>
          <span
            style={{ cursor: "pointer" }}
            className=" d-flex justify-content-end mb-2"
            onClick={handleBuyClose}
          >
            <AiOutlineClose />
          </span>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                quantity: "",
              }}
              validationSchema={BuySchema}
              onSubmit={onBuy}
              enableReinitialize
            >
              {({ errors, touched, values, setFieldValue, setTouched }) => (
                <Form className="sellNFT-form">
                  <div className="row align-items-start justify-content-center">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label className="mb-2" htmlFor="propertyName">
                            Quantity:
                          </label>
                          <Field
                            name="quantity"
                            id="quantity"
                            placeholder="Enter Quantity"
                            className="form-control"
                            onChange={(e) => {
                              setFieldValue("quantity", e.target.value);
                              setTotalRedAmount(e.target.value * data?.price);
                            }}
                          />
                          {errors.quantity && touched.quantity ? (
                            <div className="input-error">{errors.quantity}</div>
                          ) : null}
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="mb-2" htmlFor="propertyName">
                            Price (In RED):
                          </label>
                          <Field
                            value={totalRedAmount}
                            name="priceinred"
                            id="priceinred"
                            placeholder="Enter Price in RED"
                            className="form-control"
                            disabled
                          />
                          {errors.priceinred && touched.priceinred ? (
                            <div className="input-error">
                              {errors.priceinred}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <Button
                        className="w-100 common-btn1"
                        variant="primary"
                        type="submit"
                      >
                        Buy
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SuperFredDetail;
