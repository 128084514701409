import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaPen, FaShareFromSquare, FaCopy } from "react-icons/fa6";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import CountryFlag from "../components/Countryflag/CountryFlag";
import { Password } from "../auth/Password";
// import { Formik, Field } from "formik";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import Buy from "../images/icon7.svg";
import pdf from "../images/pdf.svg";
import { AiOutlineEye,AiOutlineEyeInvisible } from "react-icons/ai";
import Dummy from "../images/dummy.png";
import Select from "react-select";
import countryList from "react-select-country-list";
import {
  getMyInfo,
  UpdateProfile,
  changePassword,
} from "../services/propertyServices";
import { FileUploader } from "react-drag-drop-files";
import { BsPencilSquare } from "@react-icons/all-files/bs/BsPencilSquare";
import { MdDeleteForever } from "@react-icons/all-files/md/MdDeleteForever";
import { fireToast } from "../common/Toster";
import SuccessModal from "../common/successModal";
import Loader from "../components/loader/loader";

const Settings = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [success, setSuccess] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [newPasswordType, setnewPasswordType] = useState("password");

  const options = useMemo(() => countryList().getData(), []);
  const fileTypes = [
    "Accepted image formats : JPG",
    "PNG",
    "jpeg",
    " (max. 5MB)",
    "pdf",
  ];
  const [image, setImage] = useState(null);
  const [image1, setImage1] = useState(null);

  const handleimage = (file) => {
    console.log(file);
    let File = URL.createObjectURL(file);
    setImage(File);
  };

  const editUploadedFile = (e) => {
    setImage1(e.target.files[0]);
    let File = URL.createObjectURL(e.target.files[0]);
    setImage(File);
  };

  const [data, setdata] = useState({
    name: "",
    contact: "",
    walletAddress: "",
  });
  const SignupSchema = Yup.object().shape({
    // propertyName: Yup.string().required("Property name is required"),
    // propertyDescription: Yup.string().required("Description is required"),
    // addressLine1: Yup.string().required("Address line 1 is required"),
    // addressLine2: Yup.string().required("Address line 2 is required"),
    // state: Yup.string().required("State is required"),
    // country: Yup.string().required("Country is required"),
    // pincode: Yup.string().required("Pin code is required"),
  });

  useEffect(() => {
    getdata();
  }, []);
  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    fireToast("success", "Copied!");
  };
  const [myRef, setMyRef] = useState("");
  const [refBy, setRefBy] = useState("");
  const [passwordData, setPasswordData] = useState({
    oldPassword: "",
    newPassword: "",
  });
  const [username, setUsername] = useState("");
  const getdata = async () => {
    await getMyInfo()
      .then((res) => {
        setdata({
          name: res?.data?.name,
          contact: res?.data?.contact,
          walletAddress: res?.data?.walletAddress,
        });
        setMyRef(res?.data?.myReferralCode);
        setRefBy(res?.data?.referralCode);
        setImage(res?.data?.profileImage);
        setUsername(res?.data?.userName);
        formik.setFieldValue("name", res?.data?.name);
        formik.setFieldValue("contact", res?.data?.contact);
        formik.setFieldValue("walletAddress", res?.data?.walletAddress);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
  const initialValues = {
    name: data.name,
    contact: data.contact,
    walletAddress: data.walletAddress,
  };

  const formik = useFormik({
    initialValues: {
      ...data,
    },

    onSubmit: (data, { resetForm }) => {
      console.log(data, "values");
      data["profileImage"] = image1;
      UpdateProfile(data)
        .then((response) => {
          if (response.status == 200) {
            getdata();
            fireToast("success", "Successfully updated!");
            setLoader(false);
          }
        })
        .catch((error) => {
          fireToast("error", error.response?.data.error);
          console.log(error);
          setLoader(false);
        });
    },
  });
  const passwordFormik = useFormik({
    initialValues: {
      ...passwordData,
    },
    validationSchema: Yup.object({
      oldPassword:Yup.string()
      .required("This Field is Required"),
      newPassword: Yup.string()
        .required("This Field is Required")
        .min(8, "Password must be 8 characters long")
        .matches(/[0-9]/, "Password requires a number")
        .matches(/[a-z]/, "Password requires a lowercase letter")
        .matches(/[A-Z]/, "Password requires an uppercase letter")
        .matches(/[^\w]/, "Password requires a symbol"),
    }),
    onSubmit: (data, { resetForm }) => {
      console.log(data, "values");
      changePassword(data)
        .then((response) => {
          if (response.status == 200) {
            fireToast("success", "Successfully updated!");
            resetForm();
            setLoader(false);
            setTimeout(() => {
              localStorage.clear();
              localStorage.removeItem("user-info");
              localStorage.removeItem("isKycCompleted");
              navigate("/login");
            }, 1500);
          }
        })
        .catch((error) => {
          fireToast("error", error.response?.data.error);
          console.log(error);
          setLoader(false);
        });
    },
  });

  return (
    <>
      {/* {loader? <Loader/> : ""} */}
      <div className="container-fluid">
        <div className="setting-wrapper ">
          <div className="row border-bottom my-4 pb-4">
            <div className="col-xl-12 p-4">
              <form onSubmit={formik.handleSubmit}>
                <Form.Group>
                  <Form.Label>Profile </Form.Label>
                  <div className="uploadImageContainer mb-4">
                    <img
                      src={image == null || image == "" ? Dummy : image}
                      alt="Avatar"
                      className="image"
                      style={{
                        height: "100px",
                        objectFit: "contain",
                        width: "100px",
                      }}
                    />
                    <div className="overlay">
                      <label htmlFor="fileUploader" className="overlayBtn">
                        <BsPencilSquare />
                      </label>
                      <span className="overlayBtn" onClick={() => setImage("")}>
                        <MdDeleteForever />
                      </span>
                    </div>
                    <input
                      type="file"
                      id="fileUploader"
                      onChange={editUploadedFile}
                      hidden
                    />
                  </div>
                </Form.Group>
                <div className="row">
                  <Form.Group
                    className="col-lg-6 col-12 mb-4"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Name</Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Enter name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                      />
                      <i className="edit-icon">
                        <FaPen />
                      </i>
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="col-lg-6 col-12 mb-4"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Username</Form.Label>
                    <div className="position-relative">
                      <Form.Control type="text" disabled value={username} />
                    </div>
                  </Form.Group>

                  <Form.Group
                    className="col-lg-6 col-12 mb-4"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>Contact</Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        name="contact"
                        maxLength={10}
                        placeholder={"Enter Contact Number"}
                        type="text"
                        value={formik.values.contact}
                        onChange={formik.handleChange}
                      />
                      <i className="edit-icon">
                        <FaPen />
                      </i>
                    </div>
                  </Form.Group>

                  <Form.Group
                    className="col-lg-6 col-12 mb-4"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Wallet Address (Custodial Wallet like Metamask, Coinbase,
                      etc){" "}
                    </Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        type="text"
                        name="walletAddress"
                        id="walletAddress"
                        placeholder="Enter wallet address"
                        value={formik.values.walletAddress}
                        onChange={formik.handleChange}
                      />
                      <i className="edit-icon">
                        <FaPen />
                      </i>
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="col-lg-6 col-12 mb-4"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>My Referral Code</Form.Label>
                    <div className="position-relative">
                      <Form.Control type="text" disabled value={myRef} />
                      {myRef && (
                        <i
                          className="edit-icon"
                          onClick={() => handleCopy(myRef)}
                        >
                          <FaCopy />
                        </i>
                      )}
                    </div>
                  </Form.Group>

                  {refBy && refBy != "undefined" && (
                    <h6>
                      Referred By-{" "}
                      <span style={{ color: "#000" }}>{refBy}</span>
                    </h6>
                  )}
                </div>
                <div className="col-md-12 text-center">
                  <Button
                    className="mx-auto px-5 common-btn1"
                    variant="primary"
                    type="submit"
                  >
                    Save
                  </Button>
                </div>
              </form>
            </div>
            <div className="col-xl-12 mt-5">
              <div className="card p-4">
              <h5 className="mb-3">Change Password</h5>
              <form onSubmit={passwordFormik.handleSubmit}>
                <div className="row">
                  <Form.Group
                    className="col-lg-6 col-12 mb-4"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Old Password</Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        type={passwordType}
                        name="oldPassword"
                        autoComplete="new-password"
                        id="oldPassword"
                        placeholder="Enter Old Password"
                        value={passwordFormik.values.oldPassword}
                        onChange={passwordFormik.handleChange}
                        invalid={
                          passwordFormik.touched.oldPassword && passwordFormik.errors.oldPassword
                            ? true
                            : false
                        }
                      />
                      {passwordType == "password" ? (
                        <span>
                          <AiOutlineEye
                            onClick={() => setPasswordType("text")}
                            className="view_setting_password"
                          />{" "}
                        </span>
                      ) : (
                        <span>
                          <AiOutlineEyeInvisible
                            onClick={() => setPasswordType("password")}
                            className="view_setting_password"
                          />{" "}
                        </span>
                      )}
                    </div>
                    {passwordFormik.touched.oldPassword &&
                      passwordFormik.errors.oldPassword ? (
                      
                        <div className="text-danger">
                          {passwordFormik.errors.oldPassword}
                        </div>
                      ) : null}
                  </Form.Group>
                  <Form.Group
                    className="col-lg-6 col-12 mb-4"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>New Password</Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        type={newPasswordType}
                        name="newPassword"
                        autoComplete="new-password"
                        id="newPassword"
                        placeholder="Enter New Password"
                        value={passwordFormik.values.newPassword}
                        onChange={passwordFormik.handleChange}
                      />
                      {newPasswordType == "password" ? (
                        <span>
                          <AiOutlineEye
                            onClick={() => setnewPasswordType("text")}
                            className="view_setting_password"
                          />{" "}
                        </span>
                      ) : (
                        <span>
                          <AiOutlineEyeInvisible
                            onClick={() => setnewPasswordType("password")}
                            className="view_setting_password"
                          />{" "}
                        </span>
                      )}
                    </div>
                    {passwordFormik.touched.newPassword &&
                      passwordFormik.errors.newPassword ? (
                      
                        <div className="text-danger">
                          {passwordFormik.errors.newPassword}
                        </div>
                      ) : null}
                  </Form.Group>
                </div>

                <div className="col-md-12 text-center">
                  <Button
                    className="mx-auto px-5 common-btn1"
                    variant="primary"
                    type="submit"
                  >
                    Save
                  </Button>
                </div>
              </form>
              </div>
            </div>
          </div>

          <div className="row border-bottom my-4 pb-4 d-none">
            <div className="col-12 mb-4 pt-2">
              <h6 className="fw-500 text-black">Add Account</h6>
            </div>
            <div className="col-xl-6">
              <Form className="row user-form">
                <Form.Group
                  className="col-md-6 col-12 mb-4"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Email address</Form.Label>
                  <div className="position-relative">
                    <Form.Control type="text" placeholder="Enter email" />
                    <i className="edit-icon">
                      <FaPen />
                    </i>
                  </div>
                </Form.Group>

                <Form.Group
                  className="col-md-6 col-12 mb-4"
                  controlId="formBasicPassword"
                >
                  <Form.Label>Password</Form.Label>
                  <div className="position-relative">
                    <Form.Control type="email" placeholder="Password" />
                    <i className="edit-icon">
                      <FaPen />
                    </i>
                  </div>
                </Form.Group>
                <Form.Group
                  className="col-md-6 col-12 mb-4"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Username</Form.Label>
                  <div className="position-relative">
                    <Form.Control type="text" placeholder="Enter email" />
                    <i className="edit-icon">
                      <FaPen />
                    </i>
                  </div>
                </Form.Group>

                <Form.Group
                  className="col-md-6 col-12 mb-4"
                  controlId="formBasicPassword"
                >
                  <Form.Label>Contact</Form.Label>
                  <div className="position-relative">
                    <CountryFlag />
                    <i className="edit-icon">
                      <FaPen />
                    </i>
                  </div>
                </Form.Group>
                <div className="col-md-6 col-12">
                  <Button
                    className="w-100 common-btn1"
                    variant="primary"
                    type="submit"
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </div>
          </div>

          <div className="row border-bottom my-4 pb-4 d-none">
            <div className="col-12 mb-3">
              <h6 className="fw-500 text-black">Invite Friend</h6>
            </div>
            <div className="col-xl-6">
              <Form className="row user-form">
                <Form.Group className="col-md-6 col-12" controlId="">
                  <Form.Control type="text" placeholder="KJKHHUIDNNJALK" />
                </Form.Group>
              </Form>
            </div>
          </div>
        </div>
      </div>
      {success ? <SuccessModal /> : ""}
    </>
  );
};

export default Settings;
