import React, { useEffect, useState } from "react";
import "./Transactions.css";
import { BiChevronRight } from "react-icons/bi";
import { Link } from "react-router-dom";
import Buy from "../../images/icon7.svg";
import Transfer from "../../images/icon8.svg";
import Withdrawn from "../../images/icon9.svg";
import Usdt from "../../images/usdt.svg";
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import {
  getRedTrasactions,
  getSrTrasactions,
} from "../../services/propertyServices";
import ViewTrasactionDetails from "../Modals/viewTrasactionDetails";
import Modal from "react-bootstrap/Modal";
import { FaCircleChevronLeft, FaCopy } from "react-icons/fa6";
import Loader from "../loader/loader";
import { fireToast } from "../../common/Toster";
import NoData from "../NoDataComp/NoData";

const Transactions = (data) => {
  const [modalData, setModalData] = useState("");
  const [activeTab, setActiveTab] = useState("");
  const [viewDetail, setViewDetail] = useState(false);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [apiData, setApiData] = useState([]);


  const getTransactionData=async ()=>{
    try {
      setLoading(true);
      const response = await getRedTrasactions(page, activeTab);
      if (response.status === 200) {
        setApiData(response?.data);
        setLoading(false);
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching nft data:", error);
    }
  }
  useEffect(() => {
    getTransactionData()

    // if (data?.data == "Red") {
    //   getRedTrasactions(page,activeTab)
    //     .then((res) => {
    //       setApiData(res?.data);
    //     })
    //     .catch((err) => {
    //       console.log(err, "errrr");
    //     });
    // } else if (data?.data == "Solos") {
    //   getSrTrasactions(page)
    //     .then((res) => {
    //       // console.log(res?.data,"ressss")
    //       setApiData(res?.data);
    //     })
    //     .catch((err) => {
    //       console.log(err, "errrr");
    //     });
    // } else {
    //   console.log("boq apiii");
    // }
  }, [data, page, activeTab]);
  const handleModal = (item) => {
    setViewDetail(true);
    setModalData(item);
  };
  const handleClose = () => setViewDetail(false);
  const handleShow = () => setViewDetail(true);
  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    fireToast("success", "Copied!");
  };
  return (
    <>
    {
       window.location.pathname!='/transactions' &&
       <div className="d-flex align-items-center justify-content-between">
       <h6 className="fw-600 text-black mb-0">Transactions</h6>
       {/* <Link className="view-btn" to="/accounts">
         view all <BiChevronRight />
       </Link> */}
     </div>
    }
     
      {
         window.location.pathname=='/transactions' &&
         <nav>
         <div class="nav nav-tabs transaction-navs my-4" id="nav-tab" role="tablist">
           <button  onClick={() => {
                   setActiveTab("");
                   setPage(1);
                 }} class={`nav-link ${activeTab==''?'active':''}`} id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">All</button>
           <button  onClick={() => {
                   setActiveTab("buy");
                   setPage(1);
                 }} class={`nav-link ${activeTab=='buy'?'active':''}`} id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Buy</button>
           <button  onClick={() => {
                   setActiveTab("sell");
                   setPage(1);
                 }} class={`nav-link ${activeTab=='sell'?'active':''}`} id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Sell</button>
         </div>
       </nav>
      }

      <div className="table-responsive transaction-data">
        <table className="w-100">
          
            {
              loading ? (
               <Loader />
             ) :
             <tbody >
             {apiData?.items?.length === 0 ? <div style={{marginTop:"20px"}}> <NoData /></div>   : apiData?.items?.map((item, i) => (
               <tr
                 style={{ cursor: "pointer" }}
                 onClick={() => handleModal(item)}
               >
                 <td>
                   <div className="trans-list d-flex align-items-center">
                     <i>
                       {item?.transactionType == "Receive RED" ||
                       item?.transactionType2 == "Receive Solos" ? (
                         <img src={Transfer} alt="" />
                       ) : item?.transactionType == "Buy RED" ? (
                         <img src={Buy} alt="" />
                       ) : (
                         <img src={Withdrawn} alt="" />
                       )}
                     </i>
                     <div className="ps-2">
                       <h6 className="mb-1 fw-600">
                         {item?.transactionType2 == null
                           ? item?.transactionType
                           : item?.transactionType2}
                       </h6>
                       <p
                         className={`f-12 status ${
                           item?.status == "APPROVED"
                             ? "completed"
                             : item?.status == "PENDING"
                             ? "pending"
                             : "pending"
                         } `}
                       >
                         {item?.status}
                       </p>
                     </div>
                   </div>
                 </td>
                 <td className="text-end">
                   <h6 className="fw-600 mb-2">
                     {item?.transactionType == "Solos Reward"
                       ? `${item?.solosReward} SOLOS`
                       : `${item?.totalRedToken} RED`}{" "}
                     {/* {item.token} */}
                     {/* <img src={Usdt} alt="" /> */}
                   </h6>
                   <p className="f-12">
                     Order placed on {moment(item?.updatedAt).format("lll")}
                   </p>
                 </td>
               </tr>
             ))}
 
             <tr>
               <div
                 style={{
                   marginTop: "10px",
                   marginBottom: "10px",
                   // minWidth: "200%",
                 }}
               >
                 {apiData?.totalItems > 0 ? (
                   <Pagination
                     color="primary"
                     count={Math.ceil(apiData?.totalItems / 8)}
                     page={page}
                     siblingCount={0}
                     onChange={(e, v) => setPage(v)}
                   />
                 ) : (
                   ""
                 )}
               </div>
             </tr>
           </tbody>
          }
        
        </table>
      </div>
      {viewDetail ? (
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={viewDetail}
          onHide={handleClose}
          animation={false}
        >
          <Modal.Body>
            <span
              className="back-btn fw-500 text-black"
              onClick={() => handleClose()}
            >
              <i className="pe-2 align-middle">
                <FaCircleChevronLeft />
              </i>
              Transactions History
            </span>
            <div className="px-3 my-4">
              <table className="w-100">
                <tbody>
                  <tr>
                    <td>
                      <h6 className="fw-600 mb-2">Transaction Type</h6>
                    </td>
                    <td className="text-end">
                      <div className="trans-list d-flex align-items-center">
                        {modalData?.transactionType}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6 className="fw-600 mb-2">Quantity</h6>
                    </td>
                    <td className="text-end">
                      <div className="trans-list d-flex align-items-center">
                        {modalData?.transactionType == "Solos Reward"
                          ? modalData?.solosReward
                          : modalData?.totalRedToken}{" "}
                        {modalData.token}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6 className="fw-600 mb-2">Sent By</h6>
                    </td>
                    <td className="text-end">
                      <div className="trans-list d-flex align-items-center">
                        {modalData.senderUsername}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6 className="fw-600 mb-2">Transaction Id</h6>
                    </td>
                    <td className="text-end">
                      <div className="trans-list d-flex align-items-center">
                        {modalData.hashId.slice(0, 10)}...&nbsp;
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => handleCopy(modalData.hashId)}
                        >
                          <FaCopy />
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6 className="fw-600 mb-2">Sent To</h6>
                    </td>
                    <td className="text-end">
                      <div className="trans-list d-flex align-items-center">
                        {modalData.receiverUsername}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6 className="fw-600 mb-2">Date</h6>
                    </td>
                    <td className="text-end">
                      <div className="trans-list d-flex align-items-center">
                        {moment(modalData.updatedAt).format("lll")}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        ""
      )}
    </>
  );
};

export default Transactions;
