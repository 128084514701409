import React, { useEffect, useState } from "react";
import { SDK, ChatApp } from "mirrorfly-uikit/dist";
import "mirrorfly-uikit/dist/assets/scss/bundle.css";
import { getMyInfo } from "../services/propertyServices";
const Support = () => {
  const [data, setData] = useState("");

  const initialize = async (item) => {
    const initializeObj = {
      licenseKey: "fVMu7KyatzU0FZz6gf19PAltTLJhah",
      callbackListeners: {},
    };
    const check = await SDK.initializeSDK(initializeObj);
    console.log(check, "check");
    const register = await SDK.register(`${item?.contact}`);
    console.log(register, "register");
    const login = await SDK.connect(
      register?.data?.username,
      register?.data?.password
    );
    console.log("login", login);
    const setProfile = await SDK.setUserProfile(item?.userName, ``, ``, ``, ``);
    console.log("setProfile", setProfile);
  };
  const getdata = async () => {
    const myData = await getMyInfo()
      .then(async (res) => {
        setData(res?.data);
        initialize(res?.data);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  useEffect(() => {
    getdata();
  }, []);
  return (
    <div>
      {data?.userName && (
        <ChatApp
          licenseKey="fVMu7KyatzU0FZz6gf19PAltTLJhah"
          userIdentifier={`${data?.userName == undefined ? "" : data?.userName}`}
          apiUrl="https://api-preprod-sandbox.mirrorfly.com/api/v1"
          isSandBox={true} // if you are a sandbox user it is tru
          //  customConversation={customConversation}
        />
      )}
    </div>
  );
};

export default Support;
