import React, { useEffect, useState, useRef } from "react";
import "./TopNav.css";
import User from "../../images/user.svg";
import Dummy from "../../images/userDummy2.png";
import Notification from "../../images/notification.png";
import nav1 from "../../images/nav1.svg";
import nav2 from "../../images/nav2.svg";
import nav3 from "../../images/nav3.svg";
import nav4 from "../../images/nav4.svg";
import nav5 from "../../images/nav5.svg";
import nav6 from "../../images/nav6.svg";
import nav7 from "../../images/nav7.svg";
import { useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { getMyInfo } from "../../services/propertyServices";
import Modal from "react-bootstrap/Modal";
import { FaBars } from "react-icons/fa";
import ConnectWallet from "../ConnectWallet";
import MpcWallet from "../MpcWallet";
import { fireToast } from "../../common/Toster";
import Dropdown from "react-bootstrap/Dropdown";

const TopNav = ({ title, setCustom, custom }) => {
  const divRef = useRef(null);
  const navigate = useNavigate();
  const [showText, setShowText] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("user-info");
    localStorage.removeItem("isKycCompleted");
    navigate("/login");
  };

  useEffect(() => {
    getdata();
  }, []);
  const [data, setData] = useState("");
  const getdata = async () => {
    const myData = await getMyInfo()
      .then((res) => {
        setData(res?.data);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
  const handleClickOutside = (e) => {
    if (divRef.current && !divRef.current.contains(e.target)) {
      setShowText(!showText);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="top-nav position-relative pt-xl-5 py-3 px-md-4 px-3 pb-xl-4 border-bottom d-flex align-items-center justify-content-between">
        <div className="nav-left">
          <span
            className="align-middle cursor-pointer"
            onClick={() => setCustom(!custom)}
          >
            <FaBars />
          </span>
          <h1 className="ms-md-4 fs-20 mb-0 align-middle text-capitalize d-inline-block">
            {title}
          </h1>
          {/* <p className="f-15">Good momrning Max!</p> */}
        </div>
        <div className="nav-right">
          {/* <MpcWallet /> */}
          <ConnectWallet />
          
          <Dropdown>
            <Dropdown.Toggle
              className="bg-transparent border-0 p-0"
              id="notify"
            >
              <div
          >
            <img className="notification-icon" src={Notification} alt="" />
          </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
            <div className="user-droupdown">
              <div className="d-flex align-items-center justify-content-between">
                <div className="ps-3">
                  <h3 className="f-18 m-0">Notifications</h3>
                </div>
               
              </div>
              <ul className="user-option border-top mt-3 pt-4">
                <li className="notification">
                  <a>
                    <i className="me-3">
                      <img width={20} src={Notification} alt="" />
                    </i>
                    <span>Transaction History</span>{" "}
                  </a>
                </li>
                <li className="notification">
                  <a>
                    <i className="me-3">
                      <img width={20} src={Notification} alt="" />
                    </i>
                    <span>Transaction History</span>{" "}
                  </a>
                </li>
                <li className="notification">
                  <a>
                    <i className="me-3">
                      <img width={20} src={Notification} alt="" />
                    </i>
                    <span>Transaction History</span>{" "}
                  </a>
                </li>
              </ul>
            </div>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown>
            <Dropdown.Toggle
              className="bg-transparent border-0 p-0"
              id="dropdown-basic"
            >
              <div
                
                className="user-icon"
              >
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                    border: "1px solid gray",
                  }}
                  src={data.profileImage == null ? Dummy : data.profileImage}
                  alt=""
                />
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <div className="user-droupdown" ref={divRef}>
                  <div className="d-flex align-items-center">
                    <img
                      style={{
                        width: "45px",
                        height: "45px",
                        borderRadius: "50%",
                        border: "1px solid gray",
                      }}
                      src={
                        data.profileImage == null ? Dummy : data.profileImage
                      }
                      alt=""
                    />
                    <div className="ps-3">
                      <h3 className="f-18">{data?.name}</h3>
                      <a href="/Settings">
                        <span className="edit-btn">Edit Profile</span>
                      </a>
                    </div>
                  </div>
                  <ul className="user-option border-top mt-4 pt-4">
                    <li>
                      <a href="/accounts">
                        <i className="me-3">
                          <img src={nav1} alt="" />
                        </i>
                        <span>Transaction History</span>{" "}
                      </a>
                    </li>
                    <li
                      onClick={() =>
                        fireToast(
                          "info",
                          "Add Account functionality is work in process for now."
                        )
                      }
                    >
                      <a href="#">
                        <i className="me-3">
                          <img src={nav2} alt="" />
                        </i>
                        <span>Add Account</span>{" "}
                      </a>
                    </li>
                    <li>
                      <a href="/Settings">
                        <i className="me-3">
                          <img src={nav3} alt="" />
                        </i>
                        <span>Switch Account</span>{" "}
                      </a>
                    </li>
                    <li>
                      <a href="/Settings">
                        <i className="me-3">
                          <img src={nav4} alt="" />
                        </i>
                        <span>Invite Friend</span>{" "}
                      </a>
                    </li>
                    <li>
                      <a href="/support">
                        <i className="me-3">
                          <img src={nav5} alt="" />
                        </i>
                        <span>Support</span>{" "}
                      </a>
                    </li>
                    {/* <li>
                  <a href="/">
                    <i className="me-3">
                      <img src={nav6} alt="" />
                    </i>
                    <span>Security</span>{" "}
                  </a>
                </li> */}
                    <li className="logout">
                      <a href="/">
                        <i className="me-3">
                          <img src={nav7} alt="" />
                        </i>
                        <button
                          className="bg-transparent border-0"
                          onClick={handleLogout}
                        >
                          <span>Logout</span>
                        </button>{" "}
                      </a>
                    </li>
                    {/* <li className="logout"><a href="/"><i className="me-3"><img src={nav7} alt="" /></i><button><span>Logout</span></button> </a></li> */}
                  </ul>
                </div>
            </Dropdown.Menu>
          </Dropdown>


          
        

        
        </div>
      </div>
    </>
  );
};

export default TopNav;
